import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AiFillDelete } from 'react-icons/ai';
const ResourceDetailModal = ({ isOpen, onClose, resource, onDelete }) => {
  if (!resource) return null;

  const handleDelete = async () => {
    await onDelete(resource); // Call the onDelete function passed as a prop
    onClose(); // Close the modal after successful deletion
  };
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Resource Details
              </Dialog.Title>
              <div className="mt-4">
                {
                  resource.type == "webpage" &&
                  <p className="text-sm text-gray-500 mt-2">
                    <b>URL:</b> <a href={resource.name} target="_blank">{resource.name}</a>
                  </p>
                }
                {
                  resource.type == "file" &&
                  <p className="text-sm text-gray-500 mt-2">
                    <b>Filename:</b> {resource.name}
                  </p>
                }
                {
                  resource.type == "helpdesk" &&
                  <p className="text-sm text-gray-500 mt-2">
                    <b>Name:</b> {resource.name}
                  </p>
                }

                <p className="text-sm text-gray-500 mt-2">
                  <b>Brand:</b> {resource.brand}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  <b>Type:</b> {resource.type}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  <b>Status:</b> {resource.status}
                </p>
                {
                  resource.data &&
                  <p className="text-sm text-gray-500 mt-2">
                    <b>Data:</b> {resource.data}
                  </p>
                }

                <p className="text-sm text-gray-500 mt-2">
                  <b>Train Job ID:</b> {resource.trainJobId}
                </p>
                {/* <p className="text-sm text-gray-500">
                  URL: {resource.url}
                </p>
                <p className="text-sm text-gray-500">
                  URL: {resource.url}
                </p> */}
              </div>

              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={onClose}
                >
                  Close
                </button>
                {
                  resource.type !== "helpdesk" &&
                  <button
                    type="button"
                    className="inline-flex items-center justify-center ml-4 px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={handleDelete}
                  >
                    <AiFillDelete className="mr-2" />
                    Delete
                  </button>
                }

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResourceDetailModal;
