import React, { useState, useContext } from 'react';
import Text from '../components/elements/Text';
import { NavLink, useNavigate } from 'react-router-dom';
import { BsGoogle } from 'react-icons/bs';
import { useAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheck } from 'react-icons/fa';
import { showToast } from "../components/elements/ToastManager";
const Signup = () => {
    const navigate = useNavigate();
    const { user, updateUser } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [errors,setErrors] = useState({'accountName':"Account Name can't be empty"})
    const [isLogin, setIsLogin] = useState(true);

    const onFirstNameChange = (e) => {
        setFirstName(e.target.value);
        setIsFirstNameValid(e.target.value.trim().length > 0);
    };

    const onLastNameChange = (e) => {
        setLastName(e.target.value);
        setIsLastNameValid(e.target.value.trim().length > 0);
    };
    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
    }
    const onAccountChange = (e)=>{
        var isvalid = /^[A-Za-z0-9\-]*$/.test(e.target.value)
        if(isvalid){
        setAccountName(e.target.value)
        setErrors({})
        }
        else{
            setErrors({'accountName':'Account Name can only contain alphanumeric characters and (-) '})
        }
    } 
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        setIsPasswordValid(e.target.value.length >= 6);
    }
    const CustomTickIcon = () => (
        <FaCheck size={20} color="blue" className="mr-2" />
      );
    const onSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                first_name: firstName,
                last_name: lastName,
                tenant_name: accountName,
                password,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            // if(data.domain){
            //     window.location.href=data.domain
            // }
            navigate('/emailVerification',{state:{email:email}}); // Redirect to the login page
            // showToast('success', 'Registration Successful, Please Login to Continue', 4);
        } else {
            if(response.status==400){
               var errors =  await response.json()
               if(errors?.errors){
                setErrors(errors.errors)
               }
            }
            console.error('User Registration Failed!');
            setIsLogin(false);
            showToast('error', 'Registration Failed', 4);
        }
    };
    ;
    // }


    return (
        <main >
            <section>
                <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8">
                        <div>
                            <div className="py-2 px-16 text-center">
                                <NavLink to="/admin/dashboard">
                                    <img src={process.env.REACT_APP_LOGO} alt="ShopAgent Logo" className="text-3xl font-bold uppercase no-underline dark:text-white" />
                                </NavLink>
                            </div>

                            <h2 className="text-white text-center text-base  tracking-tight text-gray-900">
                                Are you new? Sign up today
                            </h2>
                            {!isLogin && (
                                <p className="text-red-500 text-center mt-2 validation">User Registration Failed!</p>
                            )}
                        </div>


                        <form onSubmit={onSubmit} className="mt-8 space-y-6" >
                            <div className=" space-y-6 rounded-md shadow-sm">
                                <div>
                                    <label htmlFor="first-name" className="sr-only">
                                        First name
                                    </label>
                                    <input
                                        id="first-name"
                                        label="First name"
                                        value={firstName}
                                        onChange={onFirstNameChange}
                                        name="firstName"
                                        type="text"
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="First name"
                                    />
                                </div>
                                {!isFirstNameValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        First name field can't be empty.
                                    </span>
                                )}
                                <div>
                                    <label htmlFor="last-name" className="sr-only">
                                        Last name
                                    </label>
                                    <input
                                        id="last-name"
                                        label="Last name"
                                        value={lastName}
                                        onChange={onLastNameChange}
                                        name="lastName"
                                        type="text"
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Last name"
                                    />
                                </div>
                                {!isLastNameValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        Last name field can't be empty.
                                    </span>
                                )}
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        label="Email address"
                                        value={email}
                                        onChange={onEmailChange}
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Email address"
                                    />
                                </div>
                                {!isEmailValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        Please enter a valid email address.
                                    </span>
                                )}
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        Account Name
                                    </label>
                                    <input
                                        type="text"
                                        label="Account Name"
                                        value={accountName}
                                        onChange={onAccountChange}
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Account Name"
                                    />
                                </div>
                                <span>This is the subdomain to access your admin login.</span>
                                <br></br>
                                {/* <span> {`{Account Name}.azule.ai`}</span> <br></br> */}
                                {errors?.accountName && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        {errors?.accountName}
                                    </span>
                                )}
                                <div>
                                    <label htmlFor="password" className="sr-only">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        label="Create password"
                                        value={password}
                                        onChange={onPasswordChange}
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Password"
                                    />
                                </div>
                                {!isPasswordValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        Please enter a password with at least 6 characters.
                                    </span>
                                )}
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Sign up
                                </button>
                            </div>

                        </form>


                        <p className="text-sm text-white text-center">
                            Already have an account?{' '}
                            <NavLink to="/login" className="underline text-tertiary">
                                Sign in
                            </NavLink>
                        </p>
                        {/* <div
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                            <div style={{ flex: 1, height: '1px', backgroundColor: 'lightgrey' }} />

                            <div>
                                <p style={{ width: '70px', color: 'lightgrey', textAlign: 'center', fontSize: '13px' }}>OR</p>
                            </div>

                            <div style={{ flex: 1, height: '1px', backgroundColor: 'lightgrey' }} />
                        </div> */}

                        {/* <button
                            onClick={onLoginGoogle}
                            className="group relative flex items-center w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >

                            &nbsp;&nbsp;Continue&nbsp;with&nbsp;<BsGoogle />oogle
                        </button> */}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Signup