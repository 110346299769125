import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContextProvider } from "./components/context/AuthContext";
import { ChatContextProvider } from "./components/context/ChatContext";
import { WebSocketProvider } from "./components/context/WebSocketContext";
import { AuthProvider } from "./context/AuthContext";
import { CookiesProvider } from 'react-cookie';
import { ReactFlowContext } from './store/context/ReactFlowContext'
import { IntegrationContext } from './store/context/integrationContext'
import { ChatBotContext } from './store/context/chatbotcontext'

import ConfirmContextProvider from './store/context/ConfirmContextProvider'
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <WebSocketProvider>
        <AuthProvider>
          <AuthContextProvider>
            <ChatContextProvider>
              <ConfirmContextProvider>
                <ReactFlowContext>
                  <IntegrationContext>
                    <ChatBotContext>

                      {/* <React.StrictMode> */}
                      <App />
                    </ChatBotContext>
                  </IntegrationContext>
                </ReactFlowContext>
              </ConfirmContextProvider>
              {/* </React.StrictMode> */}
            </ChatContextProvider>
          </AuthContextProvider>
        </AuthProvider>
      </WebSocketProvider>
    </CookiesProvider>
  </Provider>,

  document.getElementById("root")
);
