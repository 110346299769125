import React, { useState, useEffect } from 'react';
import { useAction } from './ActionContext'; // Import useCategory
import RulesetList from './RulesetList';
import LoadingScreen from 'react-loading-screen';
import { useAuth } from '../../../../context/AuthContext';

const Rulesets = ({ chatbotId, chatBotName, actions = [] }) => {
  const { selectedAction } = useAction(); // Use useCategory
  const { user } = useAuth()
  const [rulesets, setRulesets] = useState([]);
  const [categories, setCategories] = useState(actions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    setCategories(actions)

  },[actions])
  
  // const fetchCategories = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/action/list`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "Authorization":`Bearer ${user.token}`,
  //       },
  //       body: JSON.stringify({ agentId: chatbotId }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       // console.log(data)
  //       setCategories(data);
  //     } else {
  //       console.error('Error fetching categories:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching categories:', error);
  //   }
  // };
  const fetchRulesets = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/description/list_policies`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          agentId: chatbotId, // Replace this with the actual chatbotId
          actionName: selectedAction,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setRulesets(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching rulesets:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedAction != "All") {
      fetchRulesets();
    }
  }, [chatbotId, selectedAction]);

  return (
    <div>
      <h1 className="text-2xl mt-2 ml-6 text-light">Policies</h1>
      {isLoading && <LoadingScreen
        loading={isLoading}
        bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
        spinnerColor=" rgb(9 199 225)"
        textColor="#676767"
      ></LoadingScreen>
      }
      {!isLoading &&
        <RulesetList
          rulesets={rulesets}
          setRulesets={setRulesets}
          categories={categories}
          chatbotId={chatbotId}
          chatBotName={chatBotName}
          handleRefresh={fetchRulesets}
        />
      }

    </div>
  );
};

export default Rulesets;
