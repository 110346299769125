function Card(props) {
  const { variant, extra, children,rounded,bgColor, ...rest } = props;
  return (
    <div
      className={`!z-5 relative flex flex-col ${rounded==false? "":"rounded-[20px]"} ${bgColor?bgColor:"bg-whiteColor"} dark:bg-sidebar bg-clip-border shadow-2xl shadow-gray-200 dark:text-white dark:!shadow-none ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
