import React from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useSpring, animated } from 'react-spring';

const SubscriptionError = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 750 },
  });

  return (
    <animated.div style={fadeIn} className="min-h-screen flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 p-10 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center items-center text-red-500 mb-5">
          <AiOutlineCloseCircle size={48} />
        </div>
        <h2 className="text-2xl font-semibold text-center text-red-500 mb-5">
          Subscription Error
        </h2>
        <p className="text-gray-700 dark:text-gray-300 text-center mb-5">
          We encountered an issue while processing your subscription. Please try again later.
        </p>
        <div className="text-center">
          <NavLink to="/admin">
            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
              Continue to Dashboard
            </button>
          </NavLink>
        </div>
      </div>
    </animated.div>
  );
};

export default SubscriptionError;
