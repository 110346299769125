import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

export const useAuthCheck = () => {
  const { user, updateUser } = useAuth();
  const navigate = useNavigate();
  // console.log(user)
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }else{
      return user;
    }
  }, [user, navigate]);

  return user; // Return the authenticated user
};
