import React, { useEffect, useState } from 'react';
import AddResourceModal from './Resource.jsx';
import ResourceDetailModal from './ResourceDetailModal.jsx';
import { MdDescription, MdLink, MdLiveHelp } from 'react-icons/md';
import { showToast } from '../../elements/ToastManager'; // Import showToast function
import { useAuth } from '../../../context/AuthContext.jsx';
function ChatsList(props) {
  const {user} = useAuth()
  const { onStateChange, chatbotId, chatBotName, onOpen,openDefault } = props;
  const [resources, setResources] = useState([]);
  const [addResourceModalOpen, setAddResourceModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResource, setSelectedResource] = useState(null);
  const [resourceDetailModalOpen, setResourceDetailModalOpen] = useState(false);
  useEffect(()=>{
    if(openDefault && localStorage.getItem("showModal")){
      setAddResourceModalOpen(true)
      localStorage.removeItem("showModal")
    }  

  },[])

  const deleteResource = async (resourceToDelete) => {
    const postData = {
      agentId: chatbotId,
      agentName: chatBotName,
      trainJobId: resourceToDelete.trainJobId,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/resources`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization":`Bearer ${user.token}`,
        },
        body: JSON.stringify(postData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete resource');
      }else{
      }
  
      // Update the resources state after successful deletion
      showToast('success', 'Resource deleted', 3);
      setResources(resources.filter((resource) => resource.trainJobId !== resourceToDelete.trainJobId));
    } catch (error) {
      showToast('error', 'Error while deleting resource', 3);
      console.error('Error while deleting resource:', error);
    }
  };
  

  const fetchResources = async (chatbotId) => {
    fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/resources?chat_bot_id=${chatbotId}`, {
      method: 'GET',
      'headers':{
        "Authorization":`Bearer ${user.token}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.message === 'No data found for the provided chat_bot_id') {
          const reso = [];
          
          setResources(reso);
          // console.log(resources)
          setIsLoading(false); // Set loading status to false when resources are loaded
          return;
        }
        // Sort the resources array by createdAt in descending order (latest first)
        // res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        res.reverse()
        setResources(res);
        setIsLoading(false); // Set loading status to false when resources are loaded
      });
  }
  useEffect(() => {
    if (chatbotId) {
      fetchResources(chatbotId);
    }
  }, [chatbotId]);
  const handleResourceCardClick = (resource) => {
    setSelectedResource(resource);
    setResourceDetailModalOpen(true);
  };

  const handleSelect = (resource, index) => {
    onStateChange(resource, index + 1);
    onOpen();
  };

  const ResourceType = ({ type }) => {
    switch (type) {
      case 'helpdesk':
        return (
          <span className="flex items-center">
            <MdLiveHelp className="mr-2" />
            Helpdesk
          </span>
        );
      case 'file':
        return (
          <span className="flex items-center">
            <MdDescription className="mr-2" />
            File
          </span>
        );
      case 'url':
      case 'webpage':
        return (
          <span className="flex items-center">
            <MdLink className="mr-2" />
            URL
          </span>
        );
      default:
        return 'Unknown';
    }
  };
  const handleAddResource = (newResource) => {

    // console.log(newResource)
    fetchResources(chatbotId);
    // After successfully adding the resource, update the resources state
    // setResources([...resources, newResource]);

    // Close the AddResourceModal
    setAddResourceModalOpen(false);
  };
  const Spinner = () => (
    <div className="w-16 h-16 border-t-2 border-blue-500 rounded-full animate-spin mx-auto mt-10"></div>
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => setAddResourceModalOpen(true)}
            >
              Add Resource
            </button>
          </div>
          <AddResourceModal
            isOpen={addResourceModalOpen}
            onClose={() => setAddResourceModalOpen(false)}
            onAddResource={handleAddResource}
            chatBotId={chatbotId}
            chatBotName={chatBotName}
          />
          <ResourceDetailModal
            isOpen={resourceDetailModalOpen}
            onClose={() => setResourceDetailModalOpen(false)}
            resource={selectedResource}
            onDelete={deleteResource} // Pass the deleteResource function as a prop
          />
          {
            resources.length > 0 &&
            <div>
              {resources.map((resource, index) => (
                <div
                key={index}
                onClick={() => handleResourceCardClick(resource)} // Call the click event handler
                className="cursor-pointer transition-all duration-300 bg-gray-100  hover:bg-gray-300 hover:shadow-lg rounded p-2 mb-4 mr-4 dark:bg-gray-600 dark:hover:bg-gray-500"
              >
                  <div
                    className={`flex w-full items-center gap-3 px-2 ${index === resources.length - 1 ? 'pt-3 pb-3' : 'border-b py-3'
                      }  cursor-pointer border-gray-200 dark:border-gray-600 lg:cursor-pointer`}
                  >
                    <div className="w-full">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal tracking-normal text-gray-600 dark:text-gray-200 truncate w-full">
                          <b>Resource: {resource.name}</b>
                        </p>
                      </div>
                      <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal tracking-normal text-gray-600 dark:text-gray-200">
                          Status: {resource.status}
                        </p>
                      </div>
                      <p className="text-base font-normal tracking-normal text-gray-600 dark:text-gray-200">
                        <ResourceType type={resource.type} />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
  
          {resources.length === 0 && (
            <div className="text-gray-400 text-lg pt-5 text-center">
              No resources found
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ChatsList;