import React, { useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaStarOfLife } from "react-icons/fa";
import { FiArrowRight, FiEdit, FiInbox, FiShield, FiX } from "react-icons/fi";
// import { FiStar } from "react-icons/fi";
import { Dropdown, Modal, Tab, Tabs, Accordion } from "react-bootstrap";
import { RxDotsVertical } from "react-icons/rx";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { CgMouse } from "react-icons/cg";
import { useNavigate, useParams } from 'react-router-dom';
import { useIntegration } from '../store/context/integrationContext'
import { getIntegrations } from "../utils/integration";
import { useAuth } from "../context/AuthContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
const apiURLs = [
  {
    method: "get",
    url: "https://jsonplaceholder.typicode.com/posts",
    name: "Get All Posts",
    description: "Get all the posts published",
    fields: {
      params: [
        {
          name: "id",
          required: true,
        },
      ],
      data: [],
      query: [],
    },
  },
  {
    method: "get",
    url: "https://jsonplaceholder.typicode.com/posts/{id}",
    name: "Get A Posts",
    description: "Get a particular posts.",
    fields: {
      params: [
        {
          name: "id",
          required: true,
        },
      ],
      query: [
        {
          name: "perPage",
          required: false,
        },
        {
          name: "page",
          required: false,
        },
        {
          name: "filterBy",
          required: false,
        },
      ],
      data: [],
    },
  },
  {
    method: "post",
    url: "https://jsonplaceholder.typicode.com/posts",
    name: "Submit Post",
    description: "Post a new blog",
    fields: {
      params: [],
      query: [],
      data: [
        {
          name: "title",
          required: true,
        },
        {
          name: "description",
          required: true,
        },
        {
          name: "slug",
          required: false,
        },
      ],
    },
  },
  {
    method: "put",
    url: "https://jsonplaceholder.typicode.com/posts/{id}",
    name: "Update a particular post.",
    description: "Update A Particular Post",
    fields: {
      params: [
        {
          name: "id",
          required: true,
        },
      ],
      query: [],
      data: [
        {
          name: "title",
          required: true,
        },
        {
          name: "description",
          required: true,
        },
        {
          name: "slug",
          required: false,
        },
      ],
    },
  },
  {
    method: "delete",
    url: "https://jsonplaceholder.typicode.com/posts/{id}",
    name: "Delete A Posts",
    description: "Delete a particular published",
    fields: {
      params: [
        {
          name: "id",
          required: true,
        },
      ],
      query: [],
      data: [],
    },
  },
];

const method = (type) => {
  const methods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
  };
  return methods[type];
};

export default function ViewApp(props) {
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams()
  const { user } = useAuth()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [snippet, setSnippet] = React.useState("")
  const [originalSnippet, setOriginalSnippet] = React.useState("")

  const [isEdit, setIsEdit] = React.useState(false)

  const [modalShow, setModalShow] = React.useState(false);
  const [apiSelect, setAPISelect] = React.useState(0);
  const [apiData, setAPIData] = React.useState({
    params: {},
    query: {},
    data: {},
  });
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const { integration, integrations, setSelectedIntegration, setIntegrations } = useIntegration()

  useEffect(() => {
    if (user) {
      if (integrations.length <= 0)
        getIntegrations(setIntegrations, user)
    }
    else
      navigate('/login');
  }, [])

  useEffect(() => {
    if (integration && Object.keys(integration.length > 0)) {
      setSnippet("")
      setIsEdit(false)
      setOriginalSnippet("")
      if (integration?.actions && integration?.actions[apiSelect].namespace && integration?.actions[apiSelect].script) {

        fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/getCodeSnippet`, {
          'method': 'POST',
          'headers': {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ namespace: integration?.actions && integration?.actions[apiSelect].namespace, script: integration?.actions && integration?.actions[apiSelect].script })
        }).then(res => res.text()).then(res => {
          setSnippet(res || "")
          setOriginalSnippet(res || "")
        })
      }
    }
  }, [integration, apiSelect])

  useEffect(() => {
    if (integration && Object.keys(integration).length <= 0 && integrations.length > 0) {
      var filter = integrations.filter(integ => integ.name == params.id)
      setSelectedIntegration(filter ? filter[0] : {})
    }
  }, [integrations])

  const handleSnippetChange = (code) => {

    setSnippet(code)
    if (originalSnippet == code) {
      setIsEdit(false)
    }
    else {
      setIsEdit(true)
    }


  }

  return (
    <>
      <div className="flex flex-row" style={{ "paddingLeft": "1rem" }}>
        <Button variant="outlined" size="small" aria-label="back" onClick={() => navigate('/admin/integrations')} style={{ minWidth: "auto", padding: "7px", borderColor: "#747474" }}>
          <ArrowBackIcon style={{ color: 'gray' }} />
        </Button>
        <span className="text-[20px] font-normal" style={{
          "display": "inline-flex",
          "fontWeight": "bold",
          "alignItems": "center",
          "paddingLeft": "5px"
        }}>{params.id}</span>
      </div>
      <div className="p-3 py-5">
        <div className="border rounded-md ">
          <div className="p-2.5 bg-gray-100 border-b border-gray-200 flex flex-row justify-between items-center rounded-t-md">
            <div>
              <h6 className="text-[16px] font-bold m-0">{params.id} API</h6>
            </div>
            <div className="flex flex-row justify-between api-menu">
            </div>
          </div>
          <div className="p-3 bg-white rounded-b-md">
            <div className="flex flex-row">
              <div className="bg-gray-100 border-l border-t border-b border-gray-200 rounded-l flex items-center">
                <p className="text-xs font-semibold px-2">
                  API Endpoint <FiArrowRight className="inline ml-1" />
                </p>
              </div>
              <div className="flex-grow bg-white px-2.5 border-l border-t border-b border-gray-200">
                <input
                  type="text"
                  className="w-full border-none text-xs"
                  disabled
                  value={integration.url}
                />
              </div>
              <button
                className="bg-sky-800 px-3.5 text-white text-xs rounded-r"
                onClick={handleModalShow}
              >
                Use this API
              </button>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom">
          <Offcanvas.Title>
            <span className="text-sm flex flex-row items-center">
              <img
                src="https://seeklogo.com/images/A/airtable-logo-216B9AF035-seeklogo.com.png"
                alt=""
                className="inline w-8 h-8 object-contain mr-2.5"
              />
              Setup XYZ API
            </span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-2.5">
            <div className="text-xs font-medium mb-2">
              <FaStarOfLife className="mr-1.5 text-red-500 inline text-[9px]" />{" "}
              Give a name
            </div>
            <div>
              <input className="border rounded px-3.5 py-2 text-[12px] placeholder-zinc-300 outline-none w-full" />
              <p className="text-[12px] text-gray-500 font-light py-1.5">
                Give a name to this API.
              </p>
            </div>
          </div>
          <div className="mb-2.5">
            <div className="text-xs font-medium mb-2">
              <FaStarOfLife className="mr-1.5 text-red-500 inline text-[9px]" />{" "}
              Bot token key
            </div>
            <div>
              <input className="border rounded px-3.5 py-2 text-[12px] placeholder-zinc-300 outline-none w-full" />
              <p className="text-[12px] text-gray-500 font-light py-1.5">
                To get bot token, you have to make a telegram bot with
                @botfather bot.
              </p>
            </div>
          </div>
          <button className="bg-sky-800 px-3.5 py-2 text-white text-xs rounded shadow-sm flex items-center">
            <FiInbox className="inline mr-1" /> Create
          </button>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal show={modalShow} fullscreen={true} onHide={handleModalClose}>
        <Modal.Header closeButton={!isEdit} className={`${isEdit ? "bg-black text-white" : ""} shadow-lg`}>
          <div >
            <Modal.Title>
              <span className="text-[14px] flex flex-row items-center">
                Actions Information
              </span>
            </Modal.Title>
          </div>
          {isEdit && <div>
            <Button variant="outlined" style={{ "borderColor": "white", color: "white", marginRight: "5px" }} onClick={()=>handleSnippetChange(originalSnippet)}> Discard </Button>
            <Button variant="filled" style={{ backgroundColor: "green", "color": "white" }} onClick={() => handleModalClose()}> Save </Button>

          </div>}

        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            className="flex flex-row"
            style={{ minHeight: "100%" }}
          >
            <div className="max-w-[320px] min-w-[320px]  bg-gray-50 p-4">
              <h4 className="text-[18px] font-semibold mb-5">
                Actions
              </h4>
              <div>
                {integration?.actions && integration?.actions?.map((api, index) => (
                  <div className="my-4" key={index}>
                    <button
                      className={`w-full flex items-center ${index === apiSelect && "font-semibold"
                        }`}
                      onClick={() => setAPISelect(index)}
                    >
                      <div
                        className={` w-[60px] text-xs text-white py-1 text-center rounded mr-3 font-semibold ${api?.method?.toLowerCase() === "get" && "bg-[#22D3EE]"
                          }
                        ${api?.method?.toLowerCase() === "post" && "bg-[#4ADE80]"} ${api?.method?.toLowerCase() === "put" && "bg-[#FBBF24]"
                          } ${api?.method?.toLowerCase() === "delete" && "bg-[#EF4444]"}`}
                      >
                        {/* {api.method === "get" && "GET"}
                        {api.method === "post" && "POST"}
                        {api.method === "put" && "PUT"}
                        {api.method === "delete" && "DELETE"} */}
                        {method(api?.method?.toLowerCase())}
                      </div>
                      <p>{api.name}</p>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="flex-grow flex flex-col overflow-hidden"
              style={{ maxHeight: "calc(100vh - 115px)" }}
            >

              <div
                className="overflow-auto"
                style={{
                  minHeight: "calc(50vh)",

                }}
              >
                <div className="">
                  <div className="flex flex-row justify-between items-center p-5  bg-gray-100">
                    <div>
                      <h6 className="text-[18px] font-bold m-0">
                        {integration?.actions && integration?.actions[apiSelect]?.name}
                      </h6>
                      <h6 className="text-[14px] font-light m-0 text-gray-400">
                        {integration?.actions && integration?.actions[apiSelect]?.description}
                      </h6>
                    </div>
                    <div className="flex flex-row justify-between api-menu">
                      {/* <button className="text-xs px-3 text-white py-2 rounded shadow font-light flex justify-center items-center bg-sky-800 ">
                        <CgMouse className="inline mr-2" /> Click & Test API
                      </button> */}
                    </div>
                  </div>
                  <Tabs defaultActiveKey="information" className="w-fulli mt-1 mb-4 mli-1 mri-1">
                    <Tab eventKey="information" title="Information">
                      <div
                        className="overflow-hidden"
                        style={{


                        }}
                      >
                        <div className="flex flex-row px-5">
                          <div className="bg-gray-100 border-l border-t border-b border-gray-200 rounded-l flex items-center">
                            <p className="text-xs font-semibold px-2">
                              {method(integration?.actions && integration?.actions[apiSelect]?.method?.toLowerCase())}{" "}
                              <FiArrowRight className="inline ml-1" />
                            </p>
                          </div>
                          <div className="flex-grow bg-white px-2.5 border-r border-t border-b border-gray-200 rounded-r">
                            <input
                              type="text"
                              className="w-full border-none text-xs"
                              disabled
                              value={integration?.actions && integration?.actions[apiSelect]?.url}
                            />
                          </div>
                        </div>
                        <div
                          className="overflow-auto mt-5 px-5"
                          style={{
                            maxHeight: "calc(50vh - 180px)",
                          }}
                        >
                          <Accordion>
                            {integration?.actions && integration?.actions[apiSelect]?.fields?.params &&
                              integration?.actions[apiSelect]?.fields?.params.length > 0 && (
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="outline-none">
                                    Params
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {/* {apiURLs[apiSelect].fields.params} */}
                                    {integration?.actions[apiSelect]?.fields?.params.map(
                                      (field) => (
                                        <div className="flex flex-row my-5">
                                          <div className="bg-gray-100 border-l border-t border-b border-gray-200 rounded-l flex items-center">
                                            <p className="text font-semibold px-2">
                                              {field.name}{" "}
                                              {field.required && (
                                                <FaStarOfLife className="mr-1.5 text-red-500 inline text-[9px]" />
                                              )}
                                            </p>
                                          </div>
                                          <div className="flex-grow bg-white px-3 border-r border-t border-b border-gray-200 rounded-r">
                                            <input
                                              type="text"
                                              className="w-full border-none"
                                            // value={}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              )}
                            {integration?.actions && integration?.actions[apiSelect]?.fields?.query &&
                              integration?.actions[apiSelect]?.fields?.query?.length > 0 && (
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header className="outline-none">
                                    Query
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {integration?.actions[apiSelect]?.fields?.query?.map(
                                      (field) => (
                                        <div className="flex flex-row my-5">
                                          <div className="bg-gray-100 border-l border-t border-b border-gray-200 rounded-l flex items-center">
                                            <p className="text font-semibold px-2">
                                              {field.name}{" "}
                                              {field.required && (
                                                <FaStarOfLife className="mr-1.5 text-red-500 inline text-[9px]" />
                                              )}
                                            </p>
                                          </div>
                                          <div className="flex-grow bg-white px-3 border-r border-t border-b border-gray-200 rounded-r">
                                            <input
                                              type="text"
                                              className="w-full border-none"
                                            // value={}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              )}
                            {integration?.actions && integration?.actions[apiSelect]?.fields?.data &&
                              integration?.actions[apiSelect]?.fields?.data?.length > 0 && (
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header className="outline-none">
                                    Data
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {integration?.actions[apiSelect]?.fields?.data?.map((field) => (
                                      <div className="flex flex-row my-5">
                                        <div className="bg-gray-100 border-l border-t border-b border-gray-200 rounded-l flex items-center">
                                          <p className="text font-semibold px-2">
                                            {field.required && (
                                              <FaStarOfLife className="mr-1.5 text-red-500 inline text-[9px]" />
                                            )}
                                            {field.name}{" "}
                                          </p>
                                        </div>
                                        <div className="flex-grow bg-white px-3 border-r border-t border-b border-gray-200 rounded-r">
                                          <input
                                            type="text"
                                            className="w-full border-none"
                                          // value={}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </Accordion.Body>
                                </Accordion.Item>
                              )}
                          </Accordion>
                        </div>
                      </div>
                      <div
                        className="overflow-auto flex-grow"
                      // style={{ maxHeight: "30vh" }}
                      >
                        <Tabs defaultActiveKey="response" className="w-fulli mt-5 mli-1 mri-1">
                          <Tab eventKey="response" title="Response">
                            <Editor
                              value={JSON.stringify((integration?.actions && integration?.actions[apiSelect].response) || {},
                                null,
                                4
                              )}
                              // onValueChange={(code) => setCode(code)}
                              highlight={(code) => highlight(code, languages.js)}
                              tabSize={4}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                              }}
                            />
                          </Tab>
                        </Tabs>
                      </div>
                    </Tab>
                    <Tab eventKey="snippet" title="Snippet">
                      <Editor
                        value={snippet || "No code snippet found"}
                        onValueChange={code => handleSnippetChange(code)}
                        // onValueChange={(code) => setCode(code)}
                        highlight={(code) => highlight(code, languages.js)}
                        padding={10}
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                        }}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>

            </div>

          </div>
        </Modal.Body>
        
      </Modal>
    </>
  );
}
const code = (url, method, params, query, data) => {
  return `const axios = require('axios');

axios({
    method: '${method}',
    url: '${url}', 
    params: ${params},
    data: ${data},
}).then(function (response) {
    // handle success
    console.log(response.data);
}).catch(function (error) {
    // handle error
    console.log(error);
})`;
};
