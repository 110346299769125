import React, { useState, useContext } from 'react';
import Text from '../components/elements/Text';
import { NavLink, useNavigate } from 'react-router-dom';

import { BsGoogle } from 'react-icons/bs';
import { useAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ConfirmForgetPassword = () => {
    const navigate = useNavigate();
    const { user, updateUser } = useAuth();
    const [password, setPassword] = useState('');
    
    const [confirmpassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLogin, setIsLogin] = useState(true);

    const onFirstNameChange = (e) => {
        setFirstName(e.target.value);
        setIsFirstNameValid(e.target.value.trim().length > 0);
    };

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        setIsPasswordValid(e.target.value.length >= 6);
        setIsConfirmPasswordValid(e.target.value==confirmpassword);
    }
    const onConfirmPasswordChange = (e)=>{
        setConfirmPassword(e.target.value);
        setIsConfirmPasswordValid(e.target.value==password);
    
    }
    const onSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/confirmForgetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email:localStorage.getItem("forgetPassEmail"),
                token: firstName,
                newpassword:password,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            navigate('/login'); // Redirect to the login page
            toast.success('Password reset successfull!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        } else {
            toast.error('Please enter a valid code!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            setIsLogin(false);
        }
    };
    ;
    // }


    return (
        <main >
            <section>
                <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8">
                        <div>
                            <div className="py-2 px-16 text-center">
                                <NavLink to="/admin/dashboard">
                                    <img src={process.env.REACT_APP_LOGO} alt="ShopAgent Logo" className="text-3xl font-bold uppercase no-underline dark:text-white" />
                                </NavLink>
                            </div>

                            <h2 className="text-white text-center text-base  tracking-tight text-gray-900">
                                Forgot Your Password?
                            </h2>
                            <p className="text-light-gray text-center text-base  tracking-tight text-gray-900">
                                We're here to help.  Please enter the code you received in at your email.
                            </p>
                            {!isLogin && (
                                <p className="text-red-500 text-center mt-2 validation">Invalid code entered!</p>
                            )}
                        </div>


                        <form onSubmit={onSubmit} className="mt-8 space-y-6" >
                            <div className=" space-y-6 rounded-md shadow-sm">
                                <div>
                                    <label htmlFor="first-name" className="sr-only">
                                        Code
                                    </label>
                                    <input
                                        id="first-name"
                                        label="Code"
                                        value={firstName}
                                        onChange={onFirstNameChange}
                                        name="code"
                                        type="text"
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Enter code"
                                    />
                                </div>
                                {!isFirstNameValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        Code field can't be empty.
                                    </span>
                                )}

                                <div>
                                    <label htmlFor="password" className="sr-only">
                                        New Password
                                    </label>
                                    <input
                                        type="password"
                                        label="Create password"
                                        value={password}
                                        onChange={onPasswordChange}
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="New Password"
                                    />
                                </div>
                                {!isPasswordValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        Please enter a password with at least 6 characters.
                                    </span>
                                )}

                                <div>
                                    <label htmlFor="password" className="sr-only">
                                        Confirm Password
                                    </label>
                                    <input
                                        type="password"
                                        label="Create password"
                                        value={confirmpassword}
                                        onChange={onConfirmPasswordChange}
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Confirm Password"
                                    />
                                </div>
                                {!isConfirmPasswordValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        The new password and confirm password fields do not match.
                                    </span>
                                )}
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Submit
                                </button>
                            </div>

                        </form>


                        <p className="text-sm text-white text-center">
                            Go back ? {' '}
                            <NavLink to="/login" className="underline text-tertiary">
                                Sign in
                            </NavLink>
                        </p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ConfirmForgetPassword