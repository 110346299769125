import React from 'react';

import Balance from './Balance';
import DailyTraffic from './DailyTraffic';
import MostVisited from './MostVisited';
import OverallRevenue from './OverallRevenue';
import ProfitEstimation from './ProfitEstimation';
import ProfitEstimation1 from './ProfitEstimation1';
import ProjectStatus from './ProjectStatus';
import YourCard from './YourCard';
import YourTransfers from './YourTransfers';
import Banner from './Banner';
import Card from '../card';
import Earn from './Earn';
import { tableColumnsMostVisited } from './variables/tableColumnsMostVisited';
import tableDataMostVisited from './variables/tableDataMostVisited';
import { NavLink } from 'react-router-dom';
const MainDashboard = () => {
  return (
    <>
      {/* Top banner */}
      <Banner />

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Earn />
        </div>
        {/* <div className="col-span-6 md:col-span-6">
          <OverallRevenue />
        </div> */}


        {/* <div className="col-span-6 md:col-span-6">
          <OverallRevenue />
        </div> */}
        {/* <div className="col-span-6 md:col-span-4">
            <ProfitEstimation1 />
          </div> */}
        {/* <div className="col-span-6 md:col-span-4">
            <ProfitEstimation1 />
          </div>
          <div className="col-span-6 md:col-span-4">
            <ProfitEstimation1 />
          </div> */}

        {/* <div className="col-span-6 sm:col-span-12 md:col-span-6 ">
          <Card extra={'p-3 h-full lg:h-full equal-width-card '}>
            <div className="mb-auto flex flex-col px-2 text-center">
              <p className="text-lg font-semibold text-gray-900 dark:text-white">
                Create an Agent
              </p>
              <p className="mt-2 px-4 text-sm font-medium text-gray-500">
                Get started by selecting the AI Agent which is best suited for your use case. Currently only Azule Chat is available. Sign up to our email list to get notified when new agents are available.
              </p>
            </div>
            <div className="mx-auto  flex h-40 w-42 items-center justify-center">
              <NavLink to="/admin/widgets" >
                <button className="create-agent-btn">
                  Create an Agent
                </button>
              </NavLink>
            </div>
          </Card>
        </div> */}
        {/* <div className="col-span-6 sm:col-span-12 md:col-span-6">
          <Card extra={'p-3 h-full lg:h-full equal-width-card'}>
            <div className="mb-auto flex flex-col px-2 text-center">
              <p className="text-lg font-semibold text-gray-900 dark:text-white">
              Coming soon: Researcher
              </p>
              <p className="mt-2 px-4 text-sm font-medium text-gray-500">
              An AI agent which can research on your behalf. Give Researcher a goal and it will develop its own plan and tasks. Researcher is able to autonomously search through hundreds of websites and documents.
              </p>
            </div>

           
            <div className="mx-auto  flex h-40 w-42 items-center justify-center">
              <button className="create-agent-btn">
                  Check back very soon!
                </button>
            </div>
          </Card>
        </div> */}

        {/* <div className="col-span-12 sm:col-span-12 md:col-span-4 opacity-50 pointer-events-none">
          <Card extra={'p-3 h-full lg:h-full equal-width-card'}>
            <div className="mb-auto flex flex-col px-2 text-center">
              <p className="text-lg font-semibold text-gray-900 dark:text-white">
                Create your agent and get started with Azule
              </p>
              <p className="mt-2 px-4 text-sm font-medium text-gray-500">
                You can allow your customers to interact <br></br> with your Agent  through your help desk or<br></br> through a live chat widget
              </p>
            </div>

            <div className="mx-auto  flex h-40 w-42 items-center justify-center">
              <NavLink to="/admin/widgets" >
                <button className="create-agent-btn">
                  Create an Integration or Widget
                </button>
              </NavLink>
            </div>
          </Card>
        </div> */}
        {/* <div className="col-span-12 md:col-span-12">
            <YourTransfers />
          </div> */}
      </div>
    </>
  );
};

export default MainDashboard;
