import React, { useState, useEffect } from "react";
const Input = (state) => {

  const [message, setMessage] = useState({});
  const ws = state.ws

  useEffect(() => {
    setMessage("")
  }, []);
  const handleKeyPress = (event) => { 
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (ws) {
      ws.send(JSON.stringify({ message }));
      setMessage("");
    }
  };

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={handleChange}
        value={message}
        onKeyPress={handleKeyPress}
      />
      <div className="send">
        <button type="button" onClick={handleSubmit}>Send</button>
      </div>
    </div>
  );
};

export default Input;
