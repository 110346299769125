import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrStatusGood } from 'react-icons/gr';
import { BiErrorCircle } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';
export const showToast = (type, message, duration, options = {}) => {
  const CustomTickIcon = () => (
    <FaCheck size={24} color="blue" className="mr-2" />
  );
  const CustomCrossIcon = () => (
    <BiErrorCircle size={24} color="red" className="mr-2" />
  );
  const toastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: duration * 1000, // Convert seconds to milliseconds
    icon: type === 'success' ? CustomTickIcon : CustomCrossIcon ,
    toastClassName: type === 'success' ? "bg-blue-500 text-white font-semibold p-4 rounded shadow-lg" : "bg-red-500 text-white font-semibold p-4 rounded shadow-lg",
    bodyClassName: "text-sm",
    progressClassName: "rounded-full h-1 mt-4",
    progressStyle: {
      background: type === 'success' ? 'linear-gradient(to right, #4A90E2, #50E3C2)' : 'linear-gradient(to right, #FF6B6B, #F06C6C)',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
    ...options,
  };

  if (type === 'success') {
    toast.success(message, toastOptions);
  } else if (type === 'error') {
    toast.error(message, toastOptions);
  } else {
    toast(message, toastOptions);
  }
};

export const ToastContainer = () => {
  return (
    <toast.ToastContainer />
  );
};
