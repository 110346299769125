import { createContext, useEffect, useState ,useContext} from 'react'


const initialValue = {
    integrations: [],
    integration:{},
    setIntegrations:()=>{},
    setSelectedIntegration: () => {}
}

const integrationContextFlow = createContext(initialValue)
export const useIntegration = () => {
    return useContext(integrationContextFlow);
  };
export const IntegrationContext = ({ children }) => {
    const [integration, setSelectedIntegration] = useState({})
    const [errors, setErrors] = useState({})
    const [integrations, setIntegrations] = useState([])
    
    

    

    return (
        <integrationContextFlow.Provider
            value={{
                integrations,
                integration,
                setIntegrations,
                setSelectedIntegration
            }}
        >
            {children}
        </integrationContextFlow.Provider>
    )
}