import React, { useState, useEffect } from 'react';
import { IoIosArrowForward, IoMdCart, IoMdCash, IoMdRefresh, IoIosListBox } from 'react-icons/io'; // Import the desired icons
import { useAction } from './ActionContext'; // Import useCategory
import { FiPlus } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { useAuth } from '../../../../context/AuthContext';
const Actions = ({ chatbotId, chatBotName,actions=[] }) => {
    const {user} = useAuth()
    const [categories, setCategories] = useState(actions);
    const [sCategory, setSCategory] = useState(actions.length>0?actions[0].name:'All');
    const [showModal, setShowModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryState, setNewCategoryState] = useState('active');
    const { setSelectedAction } = useAction();
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    useEffect(()=>{
        setCategories(actions)
        if(actions.length>0){
            setSCategory(actions[0].name)
            setSelectedAction(actions[0].name)
        }

    },[actions])

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const updateSelectedCategory = (updatedCategories) => {
        const activeCategories = updatedCategories.filter((cat) => cat.state === 'active');
        if (activeCategories.length > 0) {
            setSelectedAction(activeCategories[0].name);
            setSCategory(activeCategories[0].name);
        } else {
            setSelectedAction(null);
            setSCategory(null);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/action/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":`Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    agentId: chatbotId,
                    name: newCategoryName,
                    state: newCategoryState,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('New Action created:', data);
                // Refresh categories after adding a new one
                setCategories((prevCategories) => [...prevCategories, data]);
            } else {
                console.error('Error creating new category:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating new category:', error);
        }

        setNewCategoryName('');
        setNewCategoryState('active');
        toggleModal();
    };

    const handleCategoryClick = (event, categoryName) => {
        if (event.target.type === 'checkbox') return;
        setSelectedAction(categoryName);
        setSCategory(categoryName);
        // console.log(`Rulesets for ${categoryName}:`);

        categories
            .filter((category) => category.name === categoryName)
            .forEach((category) => {
                // console.log(category.rules);
            });
    };
    const handleActionDelete = async ()=>{
        try {
            const response = await fetch(
                `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/action/delete-action`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":`Bearer ${user.token}`,
                    },
                    body: JSON.stringify({
                        agentId: chatbotId,
                        actionName: categoryToDelete.name,
                        agentName: chatBotName,
                    }),
                },
            );

            if (response.ok) {
                const updatedCategories = categories.filter(
                    (cat) => cat.name !== categoryToDelete.name,
                  );
                  setCategories(updatedCategories);
                  if (sCategory === categoryToDelete.name) {
                    updateSelectedCategory(updatedCategories);
                  }
            } else {
                console.error(
                    'Error deleting category:',
                    response.statusText,
                );
            }

        } catch (error) {
            console.error('Error deleting category:', error);
        }

        setShowDeleteModal(false);
        setCategoryToDelete(null);
    }

    const Spinner = () => (
        <div className="w-16 h-16 border-t-2 border-blue-500 rounded-full animate-spin mx-auto mt-10"></div>
    );
    return (
        <div>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-xl mb-4 text-gray-700">Add New Action</h2>
                        <form onSubmit={handleSave}>
                            <label htmlFor="categoryName" className=" text-gray-700 block mb-2">
                                Action Name
                            </label>
                            <input
                                type="text"
                                id="categoryName"
                                className="w-full p-2 border rounded mb-4 text-gray-700"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                            />
                            <label htmlFor="categoryState" className="block mb-2 text-gray-700">
                                State
                            </label>
                            <select
                                id="categoryState"
                                className="w-full p-2 border rounded mb-4 text-gray-700"
                                value={newCategoryState}
                                onChange={(e) => setNewCategoryState(e.target.value)}
                            >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                                Save
                            </button>
                            <button
                                type="button"
                                className="ml-2 bg-gray-300 text-black py-2 px-4 rounded"
                                onClick={toggleModal}
                            >
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-lg mb-4 text-gray-700">
                            Are you sure you want to delete this Action?
                        </h2>
                        <div className="flex justify-end">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                                onClick={handleActionDelete}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="bg-gray-300 text-black py-2 px-4 rounded"
                                onClick={() => {
                                    setShowDeleteModal(false);
                                    setCategoryToDelete(null);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isLoading ? (
                <Spinner />
            ) : (<>
                {categories.length<=0?<p style={{textAlign:"center",fontSize:"12px"}}>No action found</p>:""}
                <ul className="list-none pl-2">
                    {categories.map((category) => (
                        <li
                            key={category.name}
                            className={`flex items-center justify-between mb-2 cursor-pointer dark:text-light p-2 rounded ${sCategory === category.name ? 'bg-blue-400 dark:bg-blue-400' : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                                } ${category.state === 'inactive' ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={(event) => {
                                if (category.state !== 'inactive') handleCategoryClick(event, category.name);
                            }}
                        >
                            <div className="flex items-center">
                                <span className="ml-2 text-lg">{category.name}</span>
                            </div>
                            <div className="flex items-center">
                                
                                <IoIosArrowForward />
                            </div>
                        </li>
                    ))}
                </ul>
                </>
            )}
        </div>
    );
};

export default Actions;
