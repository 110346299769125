import React, { useState, useEffect, useRef } from 'react';
import Card from '../card';
import Messages from './Messages';
import Input from './Input';
import Header from './Header';
import SingleMessage from './SingleMessage';
// import { showToast } from '../../../elements/ToastManager';
import { MdChevronLeft } from 'react-icons/md';

function SingleChat({ chat, open, onClose, userId_, chatId_ }) {
  const [chatLog, setChatLog] = useState([]);
  const [input, setInput] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);
  const [error, setError] = useState('');
  const [chatId, setChatId] = useState(chatId_)
  const [userId, setUserId] = useState(parseInt(userId_))
  const [messageSent, setMessageSent] = useState(false)
  const [roomId, setRoomId] = useState(localStorage.getItem("roomIdTrain", ""));
  const url = 'https://opcuqkq26pch2uvv7ch346kwpy0pzymm.lambda-url.us-east-1.on.aws/'; // Replace with your chatbot URL
  const [isTyping, setIsTyping] = useState(false);

  const wsRef = useRef(null);

  const get_connect_clients_by_name = async (roomId, name) => {
    var res = await fetch(`https://api.shopagent.ai/api/room/${roomId}/clients-connected`)
    res = await res.json()
    var data = res.data || [];
    var agent_connected = data.filter(d => d.name == name)
    return agent_connected
  }

  useEffect(() => {
    // console.log(chatId, userId)
    if (!roomId) {
      fetch("https://api.shopagent.ai/api/room", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          agentId: chatId,
          training: true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data)
          setRoomId(data); // Set roomId state with the received ID
          localStorage.setItem("roomIdTrain", data)
        })
        .catch((error) => {
          // showToast
          console.error("POST request error:", error);
        });
    }

  }, [])

  useEffect(() => {
    if (roomId) {

      const webSocketURL = `wss://api.shopagent.ai/api/room/${roomId}/websocket`;
      wsRef.current = new WebSocket(webSocketURL);

      wsRef.current.onopen = async () => {
        wsRef.current.send(JSON.stringify({ name: "livechat" }));
        
        var agent_connected = await get_connect_clients_by_name(roomId, "Agent")
        if (agent_connected.length < 1) {
          const delay = ms => new Promise(res => setTimeout(res, ms));
          await delay(500)
          // wsRef.current.send(JSON.stringify({ "id": roomId }));
        }
      };

      wsRef.current.onmessage = async (event) => {

        const messageData = JSON.parse(event.data);
        const message = messageData.message;
        // console.log(messageData)
        if (messageData?.quit == "Agent" || messageData?.quit == "agent") {
          // wsRef.current.send(JSON.stringify({ "id": roomId }));
          var agent_connected = await get_connect_clients_by_name(roomId, "anonymous")
          if (agent_connected.length < 1) {
            // wsRef.current.send(JSON.stringify({ "id": roomId }));
          }
        }
        if (messageData?.quit == "anonymous" || messageData?.quit == "anonymous") {
          // wsRef.current.send(JSON.stringify({ "id": roomId }));
          var agent_connected = await get_connect_clients_by_name(roomId, "Agent")
          if (agent_connected.length < 1) {
            // wsRef.current.send(JSON.stringify({ "id": roomId }));
          }
        }

        if (message && messageData.name !== "livechat") {
          setChatLog((prevChatLog) => [...prevChatLog, { name: 'Chatbot', message: message }]);
          setInputDisabled(false);
          setIsTyping(false);
        } else if (message) {
          if (messageSent) {
            setMessageSent(false);
          } else {
            if (messageData.name === "livechat" || messageData.name === "livechat" ) {
              setChatLog((prevChatLog) => [...prevChatLog, { name: 'User', message: message }]);
            }
          }
        }
      };

      wsRef.current.onclose = () => {
      };
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [roomId]);
  const handleSubmit = (message) => {
    setIsTyping(true);
    setMessageSent(true)
    var body = JSON.stringify({
      "message": message,
    });
    wsRef.current.send(body);
    setInputDisabled(true);
    
    // e.preventDefault();
    // const body = JSON.stringify({
    //   message: message,
    // });

    setInput('');
    // const logs = [...chatLog, { name: 'User', message: message }];
    // setChatLog(logs);
    // setError('');
    // const logs = [...chatLog, { name: 'User', message: message }];
    // setChatLog(logs);

    // fetch(url, {
    //   method: 'POST',
    //   body: body,
    // })
    //   .then(async (res) => {
    //     if (res.status === 400) {
    //       res = await res.json();
    //       console.log(res)
    //       setError(res.error);
    //       setInputDisabled(false);
    //       return;
    //     }
    //     res = await res.json();
    //     console.log(res)
    //     const log = logs.slice(0, -1);
    //     setChatLog([...logs, { name: 'Chatbot', message: res.bot_message }]);
    //     setInputDisabled(false);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${open
        ? 'translate-x-[20px] lg:translate-x-[0px]'
        : '-translate-x-[120%] lg:translate-x-[0px]'
        }`}
    >
      <Card extra={'w-full h-full p-4'}>
        {/* header */}
        {/* <Header /> */}
        <div onClick={onClose} className={'block lg:hidden'}>
          <MdChevronLeft size={36} className="text-gray-500 dark:text-white" />
        </div>

        {/* message content */}
        {chat ? (
          <div className="relative h-full rounded-xl overflow-hidden">
            <Messages messages={chatLog} isTyping={isTyping} />
            <Input onSubmit={handleSubmit} />
          </div>
        ) : (
          <div className="my-auto mx-auto font-semibold text-4xl text-gray-400">
            Start a new chat
          </div>
        )}
      </Card>
    </div>
  );
}

export default SingleChat;
