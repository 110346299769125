import { useEffect, useRef, useState, useCallback, useContext } from 'react'
import { usePrompt } from '../../utils/usePrompt'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Select from 'react-select';
import LoadingScreen from "react-loading-screen";
import { LoadingButton } from '@mui/lab';
// material-ui
import { useTheme } from '@mui/material/styles'

import { showToast } from '../../components/elements/ToastManager'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAuth } from '../../context/AuthContext';

// ==============================|| Integrations ||============================== //

const Integrations = ({ showModal, onClose, chatBotId }) => {
    const theme = useTheme()
    const [categoryExpanded, setCategoryExpanded] = useState({})
    const [accordianData, setAccodrianData] = useState({})
    const { user } = useAuth();
    const [nodes, setNodes] = useState([])
    const [getCodeLoading, setCodeLoading]= useState(false)
    const [errorCode, setErrorCode]= useState(false)
    const shareDomain = () => {

    }
    const handleAccordionChange = (category) => (event, isExpanded) => {
        const accordianCategories = { ...categoryExpanded }
        accordianCategories[category] = isExpanded
        setCategoryExpanded(accordianCategories)
    }
    const handleAccordionDataChange = (category, data) => {
        // console.log(category)
        const accordianCategories = { ...accordianData }
        console.log(accordianData,)

        accordianCategories[category] = data
        console.log(accordianData,)

        setAccodrianData({ ...accordianData, ...accordianCategories })
    }
    useEffect(async () => {
        fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/chatflows/${chatBotId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(res => setNodes(res.flowData.nodes))
    }, [])

    useEffect(async () => {
        // console.log('here',nodes)
        const final_data = {}
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i]
            if (node.data.modalData) {
                const dict = {
                    "method": node.data.modalData.method ?? 'GET',
                    "headers": {
                        "Authorization": `Bearer ${user.token}`,
                        'Content-Type': 'application/json',
                    }
                }
                if (dict.method != "GET" && node?.data?.modalData?.dataKeys?.length > 0) {
                    const data = {
                        'chat_bot_id': chatBotId,
                        'id': chatBotId
                    }
                    console.log("here")
                    node?.data?.modalData?.dataKeys.forEach(dk => {
                        console.log("here", dk, node.data.inputs[dk])
                        data[dk] = node.data.inputs[dk]
                    })
                    dict['body'] = JSON.stringify(data)
                }
                console.log(dict)
                var res = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}${node.data.modalData.url}?id=${chatBotId}`, dict)
                res = await res.json()
                final_data[node.data.name] = res
                // handleAccordionDataChange(node.data.name,res)

                // setAccodrianData({ ...accordianData,...d })

            }
        }
        setAccodrianData(final_data)
        // nodes.forEach(async node => {


        // })

    }, [nodes])
    // ==============================|| Events & Actions ||============================== //
    console.log(accordianData)
    const getVerificationEmail = async () => {
        setCodeLoading(true)
        setErrorCode(false)
        var started = Date.now();
        const dict = {
            "method": "GET",
            "headers": {
                "Authorization": `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            }
        }
        
        // make it loop every 100 milliseconds
        var interval = setInterval(async function () {

            // for 1.5 seconds
            console.log(Date.now()-started)
            if (Date.now() - started > 30000) {
                // and then pause it
                setCodeLoading(false)
                clearInterval(interval);
                setErrorCode(true)

            } else {
                var res = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/get-verification-email?id=${chatBotId}`, dict)
                res = await res.json()
                if(!res?.notFound){
                    setCodeLoading(false)
                
                    clearInterval(interval);        
                }
                setAccodrianData({ ...accordianData, ...{ "Reamaze": res } })

            }
        }, 5000);
        
    }
    console.log(accordianData,errorCode)
    const getAccordian = (data) => {
        console.log(data)
        // var list = [{ 'Title': 'Enable Email forwarding', 'link': 'https://mail.google.com/mail/u/0/#settings/filters' , 'img': '/createforwarding.png' }, { 'Title': '', 'link': '', 'img': 'src' }]
        return <>
            <p class="integrations_text">To Enable reamamze integration please follow the steps below.</p>
            <Accordion key={'Enable Email forwarding'}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    key={1}
                >
                    <Typography variant='h5'>1. {"Enable Email forwarding"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul class="integrations_text">
                        <li><span>1.1. Open the following link: <a href={"https://mail.google.com/mail/u/0/#settings/fwdandpop"} >{"https://mail.google.com/mail/u/0/#settings/fwdandpop"}</a></span></li>
                        <li>1.2. Click on "Add a forwarding address."</li>
                        <li>1.3. Enter this email address `{data?.email}` in the provided modal.</li>
                    </ul>
                    <img src="/createforwarding.png"></img>

                </AccordionDetails>
            </Accordion>
            <Accordion key={'Enable Email forwarding'}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    key={1}
                >
                    <Typography variant='h5'>2. {"Enter Verification code"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <p class="integrations_text">After completing Step 1, please proceed with the following steps:</p>
                    <ul class="integrations_text">
                        <li>2.1. {data?.notFound ? "Click the button to receive your email forwarding confirmation code." : "Copy the confirmation code below."}
                            {data?.notFound ? <LoadingButton loading={getCodeLoading} variant="text" onClick={() => { getVerificationEmail() }}>
                                <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Get Code</span>
                            </LoadingButton> : ""}
                        </li>
                        <li>{data?.content ? <><div
                            dangerouslySetInnerHTML={{ __html: data?.content?.match(/Confirmation\s*code:\s*[0-9]+/)?.length > 0 ? data?.content?.match(/Confirmation\s*code:\s*[0-9]+/)[0] : errorCode? data?.content:"" }}
                        />
                        </> : ""}</li>
                        <li>2.2. Enter the verification code in the verification field provided by your email provider.</li>
                        <li>2.3. Click the "Verify" button.</li>
                    </ul>
                    <br></br>
                    <br></br>
                    <img src="/verificationImage.png"></img>


                </AccordionDetails>
            </Accordion>
            <Accordion key={'Add Email filtering'}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    key={1}
                >
                    <Typography variant='h5'>3. {"Add  Email filtering"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul class="integrations_text">
                        <li><span>3.1. Open the following link: <a href={"https://mail.google.com/mail/u/0/#create-filter"} >{"https://mail.google.com/mail/u/0/#create-filter"}</a></span></li>
                        <li>3.2. In the "To" field, enter the email address of your support contact.</li>
                        <li>3.3. Click on "Create filter."</li>
                        <li>3.4. Check the box labeled "Forward it to" and select the email address you enabled email forwarding for in Step 1.</li>
                        <li>3.5. Click on "Create filter."</li>

                    </ul>
                    <img src="/filtermodal.png"></img>
                    <br></br>
                    <img src="/forwardaddress.png"></img>
                </AccordionDetails>
            </Accordion>
        </>

    }
    return (
        <>
            <Modal size="lg" show={showModal} onHide={() => onClose(false)} >
                <Modal.Header closeButton>
                    <Modal.Title className='text-secondary'>Integrations</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-dark'>
                    {nodes.map((node, index) => node.data.displayOnModal == false ? "" : <Accordion
                        expanded={categoryExpanded[node.data.name] || false}
                        onChange={handleAccordionChange(node.data.name)}
                        key={node.data.name}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            key={index}
                        >
                            <Typography variant='h5'>{node.data.label}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {accordianData[node.data.name]?.isReamaze ? getAccordian(accordianData[node.data.name]) :
                                <div
                                    dangerouslySetInnerHTML={{ __html: accordianData[node.data.name]?.content }}
                                />}
                        </AccordionDetails>
                    </Accordion>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" className='text-light ' onClick={() => shareDomain()}> */}
                    {/* <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Submit</span> */}
                    {/* <TiTimes style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '3px', marginTop: '2px' }} /> */}
                    {/* </Button> */}
                    <Button variant="secondary" className='text-light ' onClick={() => onClose(false)}>
                        <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Close</span>
                        {/* <TiTimes style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '3px', marginTop: '2px' }} /> */}
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default Integrations
