import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {useState, useEffect} from 'react';

// import Sidebar from './components/widgets/Sidebar';
import Home from './page/Widgets';
import Signup from './page/Signup';
import ForgetPassword from './page/ForgetPassword';
import ConfirmForgetPassword from './page/ConfirmForgetPassword';
import EmailVerifcation from './page/emailVerification';
import Login from './page/Login';
import LiveChat from './page/LiveChat';
import Profile from './page/Profile';
import Channels from './page/Channels';
import Demo from './page/Demo';

import { useAuth } from './context/AuthContext'
// import Navbar from './components/widgets/Navbar';
import Layout from "./components/widgets/Layout";

import Admin from './page/Admin';
import Chat from './components/admin/chat';
import Train from './components/admin/train';
import Billing from './components/admin/billing';
import MainDashboard from './components/admin/main';
import Success from './components/admin/billing/success';
import Error from './components/admin/billing/error';
import Canvas from './views/canvas';
import Marketplace from "./page/Marketplace";
import ViewApp from "./page/ViewApp";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import {  Dialog, DialogActions, DialogContent, OutlinedInput, DialogTitle,Button } from '@mui/material'
// routing
import { StyledButton } from './ui-component/button/StyledButton'
// defaultTheme
import themes from './themes'

import "./App.css";
import { useChatBots } from './store/context/chatbotcontext';
// project imports
// import NavigationScroll from 'layout/NavigationScroll'


function App() {
  const { loading,user } = useAuth(); 
  const customization = useSelector((state) => state.customization)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Router>
          <div className="App ">
            <section>
              <div>
                <ToastContainer />
                {!loading?<Routes>
                  <Route path="/canvas" element={<Canvas />} />
                  <Route path="/canvas/:id" element={<Canvas />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/forgetPassword" element={<ForgetPassword />} />
                  <Route path="/confirmForgetPassword" element={<ConfirmForgetPassword />} />
                  <Route path="/emailVerification" element={<EmailVerifcation />} />
                  <Route
                    path="/live/:id"
                    element={
                      <Layout>
                        <LiveChat />
                      </Layout>
                    }
                  />
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="demo" element={<Demo />} />
                  <Route path="stripe/success" element={<Success />} />
                  <Route path="stripe/Error" element={<Error />} />
                  {/* <Route path="/dashboard" element={<Dashboard />} /> */}

                  <Route path="/admin" element={<Admin />}>
                    <Route path="dashboard" element={<MainDashboard />} />
                    <Route path="chat" element={<Chat />} />
                    <Route path="train" element={<Train />} />
                    <Route path="chat/:id" element={<Chat />} />
                    <Route path="widgets" element={<Home />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="billing" element={<Billing />} />
                    <Route path="integrations" element={<Marketplace/>} />
                    <Route path="app">
                      <Route path=":id" element={<ViewApp />} />
                    </Route>
                    <Route index element={<Navigate to="dashboard" />} replace />
                  </Route>
                </Routes>
                :""}
              </div>
            </section>
          </div>
          <SubscriptionDialog></SubscriptionDialog>
        </Router>
       
        
      </ThemeProvider>
    </StyledEngineProvider>


  );
}

export default App;


function SubscriptionDialog(){
  const { loading,user } = useAuth(); 
  const {chatbots} = useChatBots()
  
  const [showDialog,setShowDialog] = useState(false)
  const navigate = useNavigate()
  useEffect(()=>{
    if(user && chatbots && chatbots.length>0){
      fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/stripe/ActiveSubscription`,{"method":"GET",headers:{"Authorization":`Bearer ${user.token}`}}).then(res=>res.json()).then(res=>{
        if(res && res.length>0 && res[0].plan.includes('free') && res[0]?.messages_count<50){
          setShowDialog(false)
          return
        }else if(res && res.length>0 && !res[0].plan.includes('free')){
          setShowDialog(false)
          return
        }else{
          setShowDialog(true)
        }
      })
    }
  },[user,chatbots])
  return <Dialog
  open={showDialog}
  fullWidth
  // maxWidth='xs'
  onClose={()=>{}}
  aria-labelledby='alert-dialog-title'
  aria-describedby='alert-dialog-description'
  PaperProps={{ sx: { borderRadius: "0.5rem" } }}
>
  <DialogTitle sx={{ fontSize: '1rem', fontWeight:"bold" }} id='alert-dialog-title'>
      {"Free Trial Limit Reached: Upgrade to Continue"}
  </DialogTitle>
  <DialogContent>

  <p className='margin-bottom-20'>Thank you for trying Azule! You have reached your free trial limit. Activate a plan to get started or book a call with us to learn more.</p>
  <h6>Basic Plan</h6>
  <ul style={{"listStyle": "disc"}}>
  <li>$19/month for 1000 messages</li>
  <li>$0.02/message for 1000+ messages</li>
  </ul>
  </DialogContent>
  <DialogActions >
     
<StyledButton isImportant={true}  variant='contained' onClick={() => {window.open("https://calendar.app.google/FWVEY7kWuPd53Ab46", "_blank");}}>
  {"Book a call"}
</StyledButton>
<StyledButton isImportant={true}  variant='contained' onClick={() => {navigate('/admin/billing');setShowDialog(false)}}>
  {"View plans"}
</StyledButton>
  </DialogActions>
</Dialog>
}


// ==============================|| APP ||============================== //

// const App = () => {
  

//   return (
//            )
// }

// export default App
