import React, { useEffect, useState } from 'react';
import Text from '../components/elements/Text';

import { useParams } from 'react-router-dom';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { BsGoogle } from 'react-icons/bs';
import { useCookies } from "react-cookie"
import LoadingScreen from "react-loading-screen";
import { useAuth } from "../context/AuthContext";
import { showToast } from "../components/elements/ToastManager";
const Login = () => {
    // console.log("here")
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const search = useLocation().search;
    const redirect_url = new URLSearchParams(search).get('redirect_url');

    const { user, updateUser } = useAuth();

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        setIsPasswordValid(e.target.value.length >= 6);
    }

    useEffect(() => {
        const checkTokenValidity = async () => {
            setLoading(false)
            if (user && redirect_url) {
                if (redirect_url) {
                    setCookie("token", user.token, { "domain": ".azule.ai", "path": "/" })
                    window.location.href = redirect_url
                }
            }
            if (user) {
                if(user?.user?.isFirstLogin==1){
                    navigate("/canvas")
                }else{
                navigate("/admin");
                }
            }
        };
        checkTokenValidity();
    }, [user]);
    const onLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        try {
            const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                showToast('success', 'Login Successful', 2);
                const data = await response.json();
                console.log(redirect_url)
                setCookie("token", data.token, { "domain": ".azule.ai", "path": "/" })
                setIsLoading(false)
                const { password, ...userWithoutPassword } = data.user;

                const userData = {
                    token: data.token,
                    user: userWithoutPassword,
                };
                // if (redirect_url) {
                //     window.location.href = redirect_url
                // }
                // if(true){
                
                updateUser(userData);
                // }
                

            } else {
                
                if (response.status == 400) {
                    const data = await response.json()
                    if (data?.data?.isVerfied==false) {
                        showToast('error', 'Email verification failed. Please verifiy your email address.', 4);
                        var res = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/resendVerifyEmail`, {
                            method: "POST",
                            body: JSON.stringify({ "email": email })
                        })
                        res = await res.json()
                        showToast('error', 'An email verification has been sent to you.', 4);
                        setIsLoading(false)
                        navigate('/emailVerification', { 'state': { 'email': email } }); // Redirect to the login page
                        return;
                    }
                }
                setIsLogin(false);
                setIsLoading(false)
                showToast('error', 'Login Failed', 4);
            }
        } catch (error) {
            setIsLogin(false);
        }
    };

    return (
        <>
            <main >
                <LoadingScreen
                    loading={loading}
                    bgColor="#1e272e"
                    spinnerColor=" rgb(9 199 225)"
                    textColor="#676767"
                ></LoadingScreen>
                <section>
                    <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md space-y-8">
                            <div>
                                <div className="py-2 px-16 text-center">
                                    <NavLink to="/">
                                        <img src={process.env.REACT_APP_LOGO} alt="ShopAgent Logo" className="text-3xl font-bold uppercase no-underline dark:text-white" />
                                    </NavLink>
                                </div>

                                {/* <h1 className="text-white text-center text-base  tracking-tight text-gray-900">
                                    Sign in
                                </h1> */}
                                {!isLogin && (
                                    <p className="text-red-500 text-center mt-2 validation">Incorrect Email / Password</p>
                                )}
                            </div>

                            <form className="mt-8 space-y-6" >
                                <div className=" space-y-6 rounded-md shadow-sm">


                                    <div>
                                        <label htmlFor="email-address" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Email address"
                                            onChange={onEmailChange}
                                        />
                                        {!isEmailValid && (
                                            <span className="text-red-500 text-xs mt-1 validation">
                                                Please enter a valid email address.
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="sr-only">
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            required
                                            className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Password"
                                            onChange={onPasswordChange}
                                        />
                                        {!isPasswordValid && (
                                            <span className="text-red-500 text-xs mt-1 validation">
                                                Please enter a password with at least 6 characters.
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <p className="text-sm text-white text-right mb-2">
                                        <NavLink to="/forgetPassword" className=" text-tertiary">
                                            Forget Password?{' '}

                                        </NavLink>
                                    </p>
                                    <button
                                        onClick={onLogin}
                                        disabled={!isEmailValid || !isPasswordValid || isLoading}
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        {isLoading ? <i className="fa fa-spinner fa-spin" style={{"margin":"auto 0"}}></i> : ""}<span style={{ "paddingLeft": "10px" }}>Login</span>
                                    </button>
                                </div>

                            </form>

                            <p className="text-sm text-white text-center">
                                Don't have an account?{' '}
                                <NavLink to="/signup" className=" text-tertiary">
                                    &nbsp;Sign up
                                </NavLink>
                            </p>

                            {/* <div
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <div style={{ flex: 1, height: '1px', backgroundColor: 'lightgrey' }} />

                                <div>
                                    <p style={{ width: '70px', color: 'lightgrey', textAlign: 'center', fontSize: '13px' }}>OR</p>
                                </div>

                                <div style={{ flex: 1, height: '1px', backgroundColor: 'lightgrey' }} />
                            </div> */}

                            {/* <button
                                onClick={onLoginGoogle}
                                className="group relative flex items-center w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >

                                &nbsp;&nbsp;Continue&nbsp;with&nbsp;<BsGoogle />oogle
                            </button> */}


                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Login