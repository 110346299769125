import Banner from "../components/profile/Banner";
import { useAuthCheck } from '../useAuthCheck';
import { useState, useEffect } from "react";

const Profile = () => {
    const [currentUser, setCurrentUser] = useState(useAuthCheck());

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            }).format(date);
        }

    };

    return (
        <div className="mt-3 h-full w-full rounded-[20px]">
            <Banner
                wallet="7MVqsRijvkNBhXSCLSKP2Gpc8HsGVqR7iWnLpZynz8DK"
                address={currentUser?.user?.email || 'User'}
                name={currentUser?.user?.first_name + " " + currentUser?.user?.last_name || 'User'}
                date={`Joined ${formatDate(currentUser?.user?.createdAt)}`}
            />
        </div>
    );
};

export default Profile;
