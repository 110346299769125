import React, { useEffect, useState, useContext } from "react";
import { FiSearch } from "react-icons/fi";
import { ImEmbed } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext';
import { da } from "date-fns/locale";
import { useIntegration } from '../store/context/integrationContext'
import { getIntegrations } from "../utils/integration";
const appsData = [
  {
    name: "Google Sheet",
    icon: "https://lh3.ggpht.com/e3oZddUHSC6EcnxC80rl_6HbY94sM63dn6KrEXJ-C4GIUN-t1XM0uYA_WUwyhbIHmVMH=w300",
    status: true,
    url: "/admin/app/google-sheet/",
    description:
      "Convert your Google Sheets into JSON API and get, add, update, & delete rows with simple API.",
  },
  {
    name: "Instagram",
    icon: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-social-platform-icon-png-image_6315976.png",
    status: false,
    url: "/admin/app/instagram/",
    description:
      "Get your Instagram feed data with JSON API without any coding.",
  },
  {
    name: "Airtable",
    icon: "https://seeklogo.com/images/A/airtable-logo-216B9AF035-seeklogo.com.png",
    status: false,
    url: "/admin/app/airtable/",
    description:
      "Quickly Convert your Airtable data sheet into secrue API and bypass API limits.",
  },
  {
    name: "Telegram Bot",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png",
    status: false,
    url: "/admin/app/telegram/",
    description:
      "Send data into your telegram channel, group with simple endpoint.",
  },
  {
    name: "Webflow",
    icon: "https://cdn.icon-icons.com/icons2/2699/PNG/512/webflow_logo_icon_169218.png",
    status: false,
    url: "/admin/app/webflow/",
    description:
      "Get your webflow CMS collections data as JSON API without any backend",
  },
  {
    name: "Spotify",
    icon: "https://cdn3.iconfinder.com/data/icons/popular-services-brands/512/spotify-512.png",
    status: false,
    url: "/admin/app/spotify/",
    description:
      "Get Spotify metadata about music artists, albums, and tracks, directly from the Spotify Data Catalogue.",
  },
];

export default function Marketplace() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState({})
  const { integrations, integration, setIntegrations, setSelectedIntegration } = useIntegration()
  useEffect(() => {
    if (!user)
      navigate('/login');
  }, []);
  useEffect(() => {
    if (user) {
      getIntegrations(setIntegrations,user)
    }

  }, [user])

  const handleActivate = (index, data) => {
    setLoading({ ...loading, ...{ [index]: true } })
    fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/activate/integration`,
      {
        'headers': { 'Authorization': `Bearer ${user.token}` },
        'method': 'post',
        body: JSON.stringify(data)
      }).then(res => res.json()).then(res => {
        setSelectedIntegration(data)
        setLoading({ ...loading, ...{ [index]: false } })
        navigate(`/admin/app/${data.name}`)
      }).catch(err => {
        setSelectedIntegration(data)
        setLoading({ ...loading, ...{ [index]: true } })
        navigate(`/admin/app/${data.name}`)
      })
  }

  return (
    <div className="p-3">
      <div className="relative mb-3">
        <FiSearch className="absolute left-3 top-3" />
        <input
          type="text"
          placeholder="Search..."
          className="pl-10 border border-slate-300 pr-2 py-2 w-full rounded placeholder-zinc-300 text-sm"
        />
      </div>
      <section className="gap-4 grid-cols-1 sm:md:grid sm:md:grid-cols-2 md:xl:grid md:xl:grid-cols-2 xl:2xl:grid xl:2xl:grid-cols-3 auto-rows-fr">
        {integrations.map((app, index) => (
          <>
            <div
              className={`${"border border-slate-300"} bg-[#ffffff9e] dark:bg-sidebar/50 rounded-lg p-4 relative`}
              extra={"flex flex-col w-full relative py-4 pb-5 px-6 h-full"}
            >
              <section className="flex opacity-90">
                <div>
                  <div className="flex flex-row mb-3">
                    <img
                      src={app.integration_icon}
                      alt="Logo"
                      className="text-gray-900 dark:text-white w-8 h-8 object-contain mr-3"
                    />
                    <h3 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white flex-grow">
                      {app.name}
                    </h3>
                  </div>
                  <p className="mt-[2px] ml-px text-sm font-light text-gray-700 dark:text-white">
                    {/* {key.description} */}
                    {app.integration_description || app.description}
                  </p>

                  <>
                    <div className="flex flex-row gap-2 pt-4">
                      {app.status ? (
                        <button
                          onClick={() => {
                            setSelectedIntegration(app);
                            navigate(`/admin/app/${app.name}`)
                          }}
                          className="flex shadow-sm items-center rounded justify-center border border-sky-800 py-1 px-3 text-sm font-light  text-sky-800 transition duration-200 hover:cursor-pointer hover:bg-sky-900 hover:text-white dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                        >
                          <ImEmbed className="mr-2" />
                          <span>Use this API</span>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleActivate(index, app)}
                          disabled={loading[index]}
                          className="flex shadow-sm items-center rounded justify-center border border-sky-800 bg-sky-800 py-1 px-3 text-sm font-light text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                        >
                          {loading[index] ? <i className="fa fa-spinner fa-spin mr-2" ></i> : <ImEmbed className="mr-2" />}

                          <span>Activate</span>
                        </button>
                      )}
                    </div>
                  </>
                </div>
              </section>
            </div>
          </>
        ))}
      </section>
    </div>
  );
}
