import { createContext, useEffect, useState ,useContext} from 'react'
import { useAuth } from '../../context/AuthContext';


const initialValue = {
    chatbots: [],
    chatBotLoading:true,
    setChatBots:()=>{},
    setChatBotLoading:()=>{},
    updateChatBots:()=>{}
   
}

const chatBotContextFlow = createContext(initialValue)
export const useChatBots = () => {
    return useContext(chatBotContextFlow);
  };
export const ChatBotContext = ({ children }) => {
    const [chatbots, setChatBots] = useState([])
    const [chatBotLoading, setChatBotLoading] = useState(true)
    const {user} = useAuth()

    useEffect(()=>{
        if(user && user.token && chatbots && chatbots.length<=0){
            fetchChatBots()
        }
    },[user])

    const fetchChatBots = ()=>{
        fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/chatbots`,{"method":"GET",headers:{"Authorization":`Bearer ${user.token}`}}).then(res=>res.json()).then((res)=>{
            setChatBots(res)
            setChatBotLoading(false)
          }).catch(err=>setChatBotLoading(false))
    }

    const updateChatBots = ()=>{
        setChatBotLoading(true)
        fetchChatBots()
    }
    

    

    return (
        <chatBotContextFlow.Provider
            value={{
                chatbots,
                chatBotLoading,
                setChatBotLoading,
                setChatBots,
                updateChatBots    
            }}
        >
            {children}
        </chatBotContextFlow.Provider>
    )
}