import axios from 'axios'
import { baseURL } from '../store/constant'

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}`,
    headers: {
        'Content-type': 'application/json'
    }
})

apiClient.interceptors.request.use(function (config) {
    const user = localStorage.getItem('user')
    const password = localStorage.getItem('password')
    

    if (user) {

        config.headers.Authorization =  `Bearer ${JSON.parse(user).token}`;
    }

    return config
})

export default apiClient
