import React, { useEffect, useState } from 'react';
import Search from './Search';
import RulesetCard from './RulesetCard';
import { FiPlus, FiX } from 'react-icons/fi';
import { RiSave3Fill } from 'react-icons/ri';
import Modal from './Modal';
import { useAction } from './ActionContext'; // Import useCategory

import { useAuth } from '../../../../context/AuthContext';
import { showToast } from '../../../elements/ToastManager';
function RulesetList({ rulesets, onUpdateRuleset, categories , chatbotId, chatBotName, handleRefresh,setRulesets }) {
  const [modalOpen, setModalOpen] = useState(false);
  const {user} = useAuth()
  const { selectedAction } = useAction(); // Use useCategory
  const [integrations, setIntegrations] = useState([])
  const [newRuleset, setNewRuleset] = useState({ name: '', text: '', state: 'active',action:'' });
  const [errors, setErrors] = useState({ name: '', text: '', state: '' ,action:''});
  const [actions , setActions] = useState([])

  useEffect(()=>{
    if(categories.length>0){
     const filtered =  categories.filter(category=>category.name==selectedAction)
     console.log(filtered,"filtered")
     setIntegrations(filtered)
     if(filtered.length>0){
      setNewRuleset({ ...newRuleset, "name": "0" })
      setActions(filtered[0]?.actions||[])
     }
    }
  },
  [selectedAction,categories])
  const validateForm = () => {
    let isValid = true;
    let tempErrors = { ...errors };

    if (newRuleset.name.trim() === '') {
      tempErrors.name = 'Please select an integration';
      isValid = false;
    } else {
      tempErrors.name = '';
    }
    if (newRuleset.action.trim() === '') {
      tempErrors.action = 'Please select an action';
      isValid = false;
    } else {
      tempErrors.action = '';
    }

    if (newRuleset.text.trim() === '') {
      tempErrors.text = 'Statement is required.';
      isValid = false;
    } else {
      tempErrors.text = '';
    }

    setErrors(tempErrors);
    return isValid;
  };


  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewRuleset({ ...newRuleset, [name]: value });
  };
  const handleIntegrationChange = (event)=>{
    const { name, value } = event.target;
    setNewRuleset({ ...newRuleset, [name]: value });
    console.log("value",value)
    var category = categories[value]
    
    setActions(category?.actions||[])
  }

  const handleSaveNewRuleset = async () => {
    if (validateForm()) {
      const action = actions[newRuleset.action]
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',  "Authorization":`Bearer ${user.token}` },
        body: JSON.stringify({
          agentId: chatbotId, // Replace with your agent ID
          agentName: chatBotName, // Replace with your agent name
          actionName: categories[newRuleset?.name]?.name,
          text: newRuleset.text,
          state: newRuleset.state,
          ghostCategory:action.ghostCategory,
          action:action
        }),
      };
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/description/add_policy`,
          requestOptions
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log('Policy saved:', data);
        showToast('success', 'Policy saved successfully', 4);
        
        await handleRefresh();
        // await refreshCategories();
        var temp = {
          key: data.key,
          state: data.state,
          text: data.Text,
          action:data.action
        }
        // Add the newly added ruleset to the existing rulesets state
        setRulesets([...rulesets, temp]);
        handleModalClose();
      } catch (error) {
        console.error('Error saving policy:', error);
      }
    }
  };
  return (
    <div className="dark p-4" style={{ maxHeight: 'calc(100vh - 85px)', overflowY: 'auto' }}>
      <Search />
      <button
        className="flex items-center justify-center text-sm text-dark my-2 bg-gray-300 py-2 px-4 rounded"
        onClick={handleModalOpen}
      >
        <FiPlus className="mr-1" />
        Add New
      </button>
      {rulesets.map((ruleset, index) => (
        <RulesetCard key={index} ruleset={ruleset} chatbotId={chatbotId} chatBotName={chatBotName} index={index} onUpdateState={onUpdateRuleset} setRulesets={setRulesets} rulesets={rulesets} />
      ))}
      <Modal isOpen={modalOpen} onClose={handleModalClose}>
        <div className="p-4 rounded">
          <h2 className="text-2xl mb-4 text-gray-700">Add Action Description</h2>
          <form>
            <label className="block mb-2 text-gray-700">
              Integration
              <select
                className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                name="name"
                value={newRuleset.name}
                onChange={handleIntegrationChange}
              >
                {/* <option value="">Select an Integration</option> */}
                {integrations.map((category, index) => (
                  <option key={index} value={index}>
                    {category.name}
                  </option>
                ))}
              </select>
              {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </label>
            <label className="block mb-2 text-gray-700">
              Statement
              <textarea
                className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                name="text"
                value={newRuleset.text}
                onChange={handleInputChange}
              />
              {errors.text && <p className="text-red-500 text-xs mt-1">{errors.text}</p>}
            </label>
            <label className="block mb-2 text-gray-700">
              Actions
              <select
                className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                name="action"
                value={newRuleset.action}
                onChange={handleInputChange}
              >
                <option value="">Select an action</option>
                {actions.map((action, index) => (
                  <option key={index} value={index}>
                    {action.label}
                  </option>
                ))}
              </select>
              {errors.action && <p className="text-red-500 text-xs mt-1">{errors.action}</p>}
            </label>

            <label className="block mb-4 text-gray-700">
              State
              <select
                className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                name="state"
                value={newRuleset.state}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </label>
            <div className="flex items-center ">

              <button
                type="button"
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded flex items-center"
                onClick={handleModalClose}
              >
                <FiX className="mr-1" />
                Cancel
              </button>
              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2  rounded flex items-center"
                onClick={handleSaveNewRuleset}
              >
                <RiSave3Fill className="mr-1" />
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default RulesetList;