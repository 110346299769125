import React from 'react';
import Text from '../elements/Text';

import { useNavigate } from 'react-router-dom';
import { AiOutlineLogout } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import { useAuth } from '../../context/AuthContext';

const Navbar = () => {
    const navigate = useNavigate();
    const { updateUser } = useAuth();

    const handleLogout = () => {

        // Sign-out successful.
        updateUser(null); // Clear user data from the AuthContext
        navigate("/");
        //    
    }

    return (
        <>
            <nav className="flex justify-between pt-8">
                <div onClick={() => { navigate("/") }} className="cursor-pointer">
                    <Text className="text-white font-bold text-xl" >
                        &nbsp;&nbsp;&nbsp;&nbsp;Shop<span className="text-tertiary">Agent</span>
                    </Text>
                </div>

                <Button variant="danger" onClick={handleLogout} className="py-1 px-6 logout-button ">
                    <AiOutlineLogout />
                </Button>
            </nav>
        </>
    )
}

export default Navbar;
