import React, { useState, useEffect, useRef } from 'react';
import Card from '../components/elements/Card';
import Text from '../components/elements/Text';
import { useNavigate, NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Select from 'react-select';
import LoadingScreen from "react-loading-screen";
import { TiTick, TiTimes } from "react-icons/ti";
import { AiOutlineMessage, AiOutlineSearch, AiOutlineShop } from "react-icons/ai";
import { ImEmbed } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { MdModelTraining } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import { useAuthCheck } from '../useAuthCheck';
import { useAuth } from '../context/AuthContext';
import logoImage from '../components/img/azchat.png';
import { FiEdit } from 'react-icons/fi';
import { showToast } from '../components/elements/ToastManager';
import Integrations from '../views/integrations';
import { useChatBots } from '../store/context/chatbotcontext';
const Home = () => {
    const { user } = useAuth();
    const initialFormState =
    {
        name: "",
        description: "",
        openidkey: "",
        agent: "",
        maxtokens: "",
        sender_name: "",
        reply_to_email: "",
        llmtools: [],
        state: "",
    }
    const navigate = useNavigate();
    const defaultCards = [
        {
            id: 'default',
            name: 'Azule Chat',
            description: "Add live chat customer support to any website",
            agent: 'Azule Agent',
            enabled: true,
        },
    ];
    const [user_id, setUserId] = useState("")
    const [token, setToken] = useState("")
    const [cards, setCards] = useState(defaultCards);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState(initialFormState);
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [domainModal, setDomainModal] = useState(false)
    const [embedChatId, setEmbedChatId] = useState()
    const [domain, setDomain] = useState("")
    const [widgetUrl, setWidgetUrl] = useState(window.location.origin)
    const [validDomain, setValidDomain] = useState(false);
    const [urls, setUrls] = useState(['']);
    const [shouldProcessCard, setShouldProcessCard] = useState(false);
    const { chatbots,chatBotLoading } = useChatBots()
    // Event Handlers

    const handleEditClick = (index) => {
        navigate(`/canvas/${index}`)
    };

    useEffect(() => {
        const validateDomain = () => {
            const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
            setValidDomain(regex.test(domain));
        }
        validateDomain();
    }, [domain]);

    const showDomainModal = (key) => {
        setEmbedChatId(key)
        setDomainModal(true)

    };


    useEffect(() => {
        async function handlePostResponse() {
            if (shouldProcessCard) {
                const nonDefaultCards = cards.filter(card => card.id !== 'default');
                const lastCard = nonDefaultCards[nonDefaultCards.length - 1];
                console.log(lastCard)
                const configData = {
                    namespace: lastCard.name,
                    purpose_summary: lastCard.description,
                    agent: lastCard.agent,
                    // senderName: lastCard.senderName,
                    // replyToEmail: lastCard.replyToEmail,
                };
                console.log(cards);
                // await updateConfig(lastCard.id, configData, user_id);
                setIsLoadingScreen(false);
                // handleNavigateTrain(lastCard.id, lastCard.name);
                setShouldProcessCard(false);
            }
        }

        handlePostResponse();
    }, [cards]);

    const handleShowDefault = (data) => {
        setFormData({ ...formData, agent: { value: data, label: data, description: data } });
        setShow(true);
    };

    const handleNavigate = (chatbot, chat_bot_id) => {
        navigate(`/admin/chat`, { state: { value: chat_bot_id } })
    };
    const handleNavigateTrain = (chat_bot_id, chat_bot_name) => {
        navigate(`/admin/train`, { state: { value: chat_bot_id, name: chat_bot_name } })
    };
    useEffect(() => {
        if (user) {
            setUserId(user.user.id);
            setToken(user.token);

        } else {
            navigate("/login");
        }
    }, [user]);

    useEffect(() => {
        setCards(chatbots);
    }, [chatbots])

    useEffect(()=>{
        setIsLoadingScreen(chatBotLoading);
        
      },[chatBotLoading])

    return (
        <>
            <section className="text-white pt-0 pb-10" style={{ minHeight: "90vh" }}>
                {isLoadingScreen && <LoadingScreen
                    loading={isLoadingScreen}
                    bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
                    spinnerColor=" rgb(9 199 225)"
                    textColor="#676767"
                ></LoadingScreen>
                }

                <section className="gap-4 grid-cols-1 sm:md:grid sm:md:grid-cols-1 md:xl:grid md:xl:grid-cols-2 xl:2xl:grid xl:2xl:grid-cols-3 auto-rows-fr">


                    {cards.map((key, index) => (
                        <>
                            {!isLoadingScreen && (
                                <div
                                    className={`${"cursor-pointer hover:border hover:border-slate-500"
                                        } bg-[#ffffff9e] dark:bg-sidebar/50 rounded-lg p-4 relative`}
                                    key={index}
                                    extra={"flex flex-col w-full relative pt-4 pb-5 px-6 pb-7 h-full"}
                                >
                                    {
                                        key?.id !== "default" &&
                                        <button
                                            className="absolute top-1 right-1 bg-red-500 text-white rounded w-8 h-8 flex items-center justify-center hover:bg-red-600 transition duration-200"
                                            onClick={() => handleEditClick(key.id)}
                                        >
                                            <FiEdit size="18" />
                                        </button>
                                    }

                                    <section className="flex justify-center items-center text-center opacity-90">
                                        <div>
                                            <h3 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white">
                                                {key.name}
                                            </h3>
                                            <div className='w-[80px] m-auto'>
                                                <img src={logoImage} alt="Logo" className="text-gray-900 dark:text-white" />
                                            </div>
                                            <h6 className="mt-[2px] mb-4 ml-px text-sm font-bold text-gray-900 dark:text-white">
                                                {key.description}
                                            </h6>
                                            <h5 className="mt-[2px] mb-2.5 ml-px text-lg font-bold text-gray-900 dark:text-white">
                                                {key?.agent}
                                            </h5>

                                            {key?.id !== "default" && (
                                                <>
                                                    <div className="flex w-[60%] items-center justify-center m-auto gap-1 hover:cursor-pointer p-2">
                                                        <a
                                                            onClick={() => handleNavigate(key, key.id)}
                                                            className="text-sm font-semibold text-gray-900 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white"
                                                        >
                                                            Browse Rooms
                                                        </a>
                                                        <div className="text-xl font-semibold text-gray-900 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
                                                            <BsArrowRight />
                                                        </div>
                                                    </div>
                                                    < div className='flex flex-row gap-2 pt-4 items-center justify-center'>
                                                        {/* <button
                                                            className="items-center  rounded-lg justify-center bg-sky-800 py-1 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                                                            onClick={() => showDeleteModal(key.id)}
                                                        >
                                                            Remove
                                                        </button> */}
                                                        <button
                                                            className="flex items-center rounded-lg justify-center bg-sky-800 py-1 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                                                            onClick={() => showDomainModal(key.id)}
                                                        >
                                                            <ImEmbed className="mr-2" />
                                                            <span>Integrations</span>
                                                        </button>
                                                        <button
                                                            className="flex items-center rounded-lg justify-center bg-sky-800 py-1 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-sky-900 active:bg-sky-700 dark:bg-sky-600 dark:text-white dark:hover:bg-sky-700 dark:active:bg-sky-200"
                                                            onClick={() => handleNavigateTrain(key.id, key.name)}
                                                        // disabled={!key.enabled}
                                                        >
                                                            <MdModelTraining className='mr-2' size="20" />
                                                            <span>Training</span>
                                                        </button>
                                                    </div>
                                                </>
                                            )
                                            }
                                            {
                                                (key?.id === "default" && key?.agent === "Azule Agent") &&
                                                <div className="flex flex-row gap-2 justify-center mb-4">
                                                    <button
                                                        onClick={() => handleShowDefault("Azule Agent")}
                                                        className="flex items-center rounded-lg justify-center bg-cyan-600 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-900 active:bg-cyan-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-200"
                                                    >
                                                        <AiOutlineMessage className="mr-2" />
                                                        Add Azule Agent
                                                    </button>
                                                </div>
                                            }
                                            {
                                                (key?.id === "default" && key?.agent === "Shop Agent") &&
                                                <div className="flex flex-row gap-2 justify-center mb-4">
                                                    <button
                                                        onClick={() => handleShowDefault("Shop Agent")}
                                                        className="flex items-center rounded-lg justify-center bg-cyan-200 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-200 active:bg-cyan-200 dark:bg-cyan-200 dark:text-white dark:hover:bg-cyan-200 dark:active:bg-cyan-200"
                                                        disabled
                                                    >
                                                        <AiOutlineShop className="mr-2" />
                                                        Add Shop Agent
                                                    </button>
                                                </div>
                                            }
                                            {
                                                (key?.id === "default" && key?.agent === "Researcher Agent") &&
                                                <div className="flex flex-row gap-2 justify-center mb-4">

                                                    <button
                                                        onClick={() => handleShowDefault("Research")}
                                                        className="flex items-center rounded-lg justify-center bg-cyan-200 py-2 px-4 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-cyan-200 active:bg-cyan-200 dark:bg-cyan-200 dark:text-white dark:hover:bg-cyan-200 dark:active:bg-cyan-200"
                                                        disabled
                                                    >
                                                        <AiOutlineSearch className="mr-2" />
                                                        Add Research Agent
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    </section>
                                </div>
                            )
                            }
                        </>
                    ))}



                    {domainModal ? <Integrations showModal={domainModal} onClose={setDomainModal} chatBotId={embedChatId}></Integrations> : ""}



                    {/* </div> */}
                    {
                        // <Modal className="modal fade z-200" show={show} onHide={handleClose} dialogClassName="modal-90w">
                        //     <Modal.Header closeButton>
                        //         <Modal.Title>{formData.state === 'edit' ? "Edit Agent" : 'Add New Agent'}</Modal.Title>
                        //     </Modal.Header>
                        //     <Modal.Body>
                        //         <Form noValidate validated={validated} onSubmit={handleSubmit} >
                        //             <FloatingLabel
                        //                 controlId="floatingTextarea1"
                        //                 label="Name"
                        //                 className="mb-3"
                        //             >
                        //                 <Form.Control
                        //                     name="name"
                        //                     type="text"
                        //                     required
                        //                     placeholder="Name"
                        //                     value={formData.name}
                        //                     onChange={handleChange}
                        //                     disabled={formData.state === 'edit'}
                        //                 // autoFocus
                        //                 />
                        //                 <Form.Control.Feedback type="invalid">
                        //                     Name is required
                        //                 </Form.Control.Feedback>
                        //             </FloatingLabel>
                        //             <FloatingLabel
                        //                 controlId="floatingTextarea2"
                        //                 label="Describe your agent's purpose"
                        //                 className="mb-3"
                        //             >
                        //                 <Form.Control
                        //                     name="description"
                        //                     type="text"
                        //                     required
                        //                     placeholder="Describe your agent's purpose"
                        //                     value={formData.description}
                        //                     onChange={handleChange}

                        //                 />
                        //                 <Form.Control.Feedback type="invalid">
                        //                     Description is required
                        //                 </Form.Control.Feedback>
                        //             </FloatingLabel>
                        //             <FloatingLabel
                        //                 controlId="floatingTextarea22"
                        //                 label="Sender Name"
                        //                 className="mb-3"
                        //             >
                        //                 <Form.Control
                        //                     name="sender_name"
                        //                     type="text"
                        //                     required
                        //                     placeholder="Sender Name"
                        //                     value={formData.sender_name}
                        //                     onChange={handleChange}

                        //                 />
                        //                 <Form.Control.Feedback type="invalid">
                        //                     Sender Name is required
                        //                 </Form.Control.Feedback>
                        //             </FloatingLabel>
                        //             <FloatingLabel
                        //                 controlId="floatingTextarea23"
                        //                 label="Reply-to Email"
                        //                 className="mb-3"
                        //             >
                        //                 <Form.Control
                        //                     name="reply_to_email"
                        //                     type="email"
                        //                     required
                        //                     placeholder="Reply-to Email"
                        //                     value={formData.reply_to_email}
                        //                     onChange={handleChange}

                        //                 />
                        //                 <Form.Control.Feedback type="invalid">
                        //                     Reply-to Email is required
                        //                 </Form.Control.Feedback>
                        //             </FloatingLabel>

                        //             {showModalField &&
                        //                 <FloatingLabel
                        //                     controlId="floatingTextarea3"
                        //                     label="OpenAI API Key"
                        //                     className="mb-3"
                        //                 >
                        //                     <Form.Control
                        //                         name="openidkey"
                        //                         type="text"
                        //                         required
                        //                         placeholder="OpenAI API Key"
                        //                         value={formData.openidkey}
                        //                         onChange={handleChange}

                        //                     />
                        //                     <Form.Control.Feedback type="invalid">
                        //                         OpenID key is required.
                        //                     </Form.Control.Feedback>
                        //                 </FloatingLabel>
                        //             }
                        //             {showModalField &&
                        //                 <FloatingLabel
                        //                     controlId="floatingTextarea3"
                        //                     label="Maximum Tokens"
                        //                     className="mb-3"
                        //                 >
                        //                     <Form.Control
                        //                         name="maxtokens"
                        //                         type="number"
                        //                         placeholder="Maximum Tokens"
                        //                         value={formData.maxtokens}
                        //                         onChange={handleMaxTokens}

                        //                     />
                        //                     {/* <Form.Control.Feedback type="invalid">
                        //                     Maximum Tokens is Required.
                        //                 </Form.Control.Feedback> */}
                        //                 </FloatingLabel>
                        //             }
                        //             {
                        //                 showModalField &&
                        //                 <Form.Group className="mb-3" controlId="floatingTextarea4">
                        //                     <Form.Label>Agents</Form.Label>
                        //                     <Select
                        //                         defaultValue={[]}
                        //                         name="agent"
                        //                         options={agentOptions}
                        //                         className="basic-single-select"
                        //                         classNamePrefix="select"
                        //                         value={formData.agent || defaultAgent}
                        //                         onChange={handleChangeSelectSingle}
                        //                         required
                        //                         isDisabled={!!defaultAgent}
                        //                     />
                        //                     {!selectDisplay1 &&
                        //                         (<Form.Label className={`invalid-feedback-select`}>Please Select an Agent</Form.Label>)}
                        //                 </Form.Group>
                        //             }
                        //             {
                        //                 showModalField &&
                        //                 <Button
                        //                     style={{ marginTop: "10px", marginBottom: "15px", border: "none" }}
                        //                     className="font-semibold rounded-lg py-2 px-10 bg-info"
                        //                     variant="dark"
                        //                     onClick={handleAddNew}
                        //                 >
                        //                     <IoIosAddCircleOutline style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '5px', marginTop: '2px' }} />
                        //                     <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>Add New</span>

                        //                 </Button>
                        //             }

                        //             {urls.map((url, index) => (
                        //                 <div key={index} className="p-2 d-flex mb-3 align-items-center w-100" style={{ width: "100%" }}>
                        //                     <div style={{ width: "80%" }}>
                        //                         {
                        //                             showModalField &&
                        //                             <FloatingLabel
                        //                                 key={index}
                        //                                 controlId={`url${index}`}
                        //                                 label={`URL ${index + 1}`}
                        //                                 className="w-100 me-3"
                        //                             >
                        //                                 <Form.Control
                        //                                     name={`url${index}`}
                        //                                     type="text"
                        //                                     placeholder="Enter URL"
                        //                                     required
                        //                                     value={url}
                        //                                     onChange={(e) => {
                        //                                         const newUrls = [...urls];
                        //                                         newUrls[index] = e.target.value;
                        //                                         setUrls(newUrls);
                        //                                     }}
                        //                                 />

                        //                                 <Form.Control.Feedback type="invalid">
                        //                                     {`URL ${index + 1}`} is required.
                        //                                 </Form.Control.Feedback>

                        //                             </FloatingLabel>
                        //                         }

                        //                     </div>
                        //                     <div style={{ width: "20%" }}>
                        //                         {
                        //                             showModalField &&
                        //                             <Button
                        //                                 style={{ marginLeft: "15px" }}
                        //                                 // className="mb-3"
                        //                                 variant="danger"
                        //                                 onClick={() => {
                        //                                     const newUrls = [...urls];
                        //                                     newUrls.splice(index, 1);
                        //                                     setUrls(newUrls);
                        //                                 }}
                        //                             >
                        //                                 <RxCross1 />
                        //                             </Button>
                        //                         }

                        //                     </div>
                        //                 </div>
                        //             ))}
                        //             {showModalField &&
                        //                 <Form.Group className="mb-3" controlId="floatingTextarea5">
                        //                     <Form.Label>LLM Tools</Form.Label>
                        //                     <Select
                        //                         defaultValue={[]}
                        //                         isMulti
                        //                         name="llmtools"
                        //                         options={toolsOptions}
                        //                         className="basic-multi-select border-red"
                        //                         classNamePrefix="select"
                        //                         required
                        //                         value={formData.llmtools}
                        //                         onChange={handleChangeSelect}
                        //                     />
                        //                     {!selectDisplay2 &&
                        //                         (<Form.Label className={`invalid-feedback-select`}>Please Select LLM Tools</Form.Label>)}
                        //                 </Form.Group>
                        //             }

                        //             {!displayTools &&
                        //                 <>
                        //                     <FloatingLabel
                        //                         controlId="floatingTextarea3"
                        //                         label="Pinecone API Key"
                        //                         className="mb-3"
                        //                     >
                        //                         <Form.Control
                        //                             name="pine_cone_api_key"
                        //                             type="text"
                        //                             required
                        //                             placeholder="Pinecone API Key"
                        //                             value={formData.pine_cone_api_key}
                        //                             onChange={handleChange}

                        //                         />
                        //                         <Form.Control.Feedback type="invalid">
                        //                             OpenAI API Key is required.
                        //                         </Form.Control.Feedback>
                        //                     </FloatingLabel>
                        //                     <FloatingLabel
                        //                         controlId="floatingTextarea3"
                        //                         label="Pinecone Environment"
                        //                         className="mb-3"
                        //                     >
                        //                         <Form.Control
                        //                             name="enivornment"
                        //                             type="text"
                        //                             required
                        //                             placeholder="Pinecone environment"
                        //                             value={formData.enivornment}
                        //                             onChange={handleChange}

                        //                         />
                        //                         <Form.Control.Feedback type="invalid">
                        //                             Pinecone environment is required.
                        //                         </Form.Control.Feedback>
                        //                     </FloatingLabel>
                        //                     <FloatingLabel
                        //                         controlId="floatingTextarea3"
                        //                         label="Pinecone Index Name"
                        //                         className="mb-3"
                        //                     >
                        //                         <Form.Control
                        //                             name="index_name"
                        //                             type="text"
                        //                             required
                        //                             placeholder="Pinecone index name"
                        //                             value={formData.index_name}
                        //                             onChange={handleChange}

                        //                         />
                        //                         <Form.Control.Feedback type="invalid">
                        //                             Index name is required.
                        //                         </Form.Control.Feedback>
                        //                     </FloatingLabel>
                        //                     <FloatingLabel
                        //                         controlId="floatingTextarea3"
                        //                         label="Pinecone Data Key"
                        //                         className="mb-3"
                        //                     >
                        //                         <Form.Control
                        //                             name="text_key"
                        //                             type="text"
                        //                             required
                        //                             placeholder="Pinecone Data Key"
                        //                             value={formData.text_key}
                        //                             onChange={handleChange}

                        //                         />
                        //                         <Form.Control.Feedback type="invalid">
                        //                             Pinecone Data Key is required.
                        //                         </Form.Control.Feedback>
                        //                     </FloatingLabel>
                        //                     <FloatingLabel
                        //                         controlId="floatingTextarea3"
                        //                         label="Pinecone Tool Name"
                        //                         className="mb-3"
                        //                     >
                        //                         <Form.Control
                        //                             name="pine_cone_tool_name"
                        //                             type="text"
                        //                             placeholder="Pinecone API Key"
                        //                             value={formData.pine_cone_tool_name}
                        //                             onChange={handleChange}

                        //                         />
                        //                     </FloatingLabel>
                        //                     <FloatingLabel
                        //                         controlId="floatingTextarea3"
                        //                         label="Pinecone Tool Description"
                        //                         className="mb-3"
                        //                     >
                        //                         <Form.Control
                        //                             name="pine_cone_tool_description"
                        //                             type="text"
                        //                             placeholder="Pinecone Tool Description"
                        //                             value={formData.pine_cone_tool_name}
                        //                             onChange={handleChange}

                        //                         />
                        //                     </FloatingLabel>
                        //                 </>

                        //             }
                        //             {showModalField && <FloatingLabel
                        //                 controlId="floatingTextarea3"
                        //                 label="Agent Prompt prefix"
                        //                 className="mb-3"
                        //             >
                        //                 <Form.Control
                        //                     name="agent_prompt_prefix"
                        //                     type="text"
                        //                     placeholder="Agent Prompt prefix"
                        //                     value={formData.agent_prompt_prefix}
                        //                     onChange={handleChange}

                        //                 />
                        //             </FloatingLabel>
                        //             }
                        //             {showModalField && <FloatingLabel
                        //                 controlId="floatingTextarea3"
                        //                 label="Agent Prompt suffix"
                        //                 className="mb-3"
                        //             >
                        //                 <Form.Control
                        //                     name="agent_prompt_suffix"
                        //                     type="text"
                        //                     isValid={!suffix_invalid}
                        //                     isInvalid={suffix_invalid}
                        //                     placeholder="Agent Prompt suffix"
                        //                     value={formData.agent_prompt_suffix}
                        //                     onChange={handleSuffixChange}
                        //                 />
                        //                 <Form.Control.Feedback type="invalid">
                        //                     Invalid prompt suffix.
                        //                 </Form.Control.Feedback>
                        //             </FloatingLabel>
                        //             }

                        //             {showModalField && <p style={{ fontSize: "11px" }}>Note: Suffix should contain chat_history, input and agent_scratchpad variables</p>}
                        //             {showModalField && <p style={{ fontSize: "11px" }}>{"E.g: Begin!{chat_history} Question: {input} {agent_scratchpad}"}</p>}

                        //             {requiredApiKeys.map((entity, index) => (
                        //                 <ApiField onChange={handleChangeApiKeys} key={index} entity={entity} />
                        //             ))}

                        //             <section className='modal-footer'>
                        //                 <Button className="font-semibold rounded-lg py-2 px-10 bg-light text-dark" variant="dark" onClick={handleClose}>
                        //                     Cancel
                        //                 </Button>
                        //                 <Button className="font-semibold rounded-lg py-2 px-10" variant="danger" type="submit" >
                        //                     Save Changes
                        //                 </Button>

                        //             </section>


                        //         </Form>
                        //     </Modal.Body>
                        //     {/* <Modal.Footer>
                        //     <Button className={"btn-btn"} variant="secondary" onClick={handleClose}>
                        //         Close
                        //     </Button>
                        //     <Button variant="success" onClick={handleSubmit}>
                        //         Save Changes
                        //     </Button>
                        // </Modal.Footer> */}
                        // </Modal>
                    }

                </section >

                <style jsx>{`
                
                @media (max-width: 768px) {
                    .grid {
                        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    }
                }
                @media (max-width: 480px) {
                    .grid {
                        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                    }
                }
            `}</style>
            </section >
        </>
    );
};
const ApiField = ({ entity, onChange }) => {
    return (
        <div>
            {
                entity.name.map((message, index) => (
                    <ApiField1 onChange={onChange} key={index} message={message} />
                ))
            }
        </div>
    )
}

const ApiField1 = ({ message, onChange }) => {

    return (

        <FloatingLabel
            controlId={`floatingTextarea`}
            label={message}
            className="mb-3"
        >
            <Form.Control
                name={message}
                type="text"
                required
                placeholder={message}
                value={message.value}
                onChange={onChange}
            />
            <Form.Control.Feedback type="invalid">
                {message} is required
            </Form.Control.Feedback>
        </FloatingLabel>
    )
}

export default Home;