import React, { useState } from 'react';
import { IoPaperPlane } from 'react-icons/io5';
import { MdImage, MdOutlineAttachment, MdOutlineTagFaces,} from 'react-icons/md';
import EmojiPicker, { Theme,} from "emoji-picker-react";

const Input = ({ onSubmit }) => {
  const [message, setMessage] = useState('');
  const [emjoiWindow, setEmojiWindow] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(message);
    setMessage('');
  };

  const onSelectEmoji = (emojiData, event) => {
    alert(emojiData.unified);
  }

  return (
    <div className="flex items-center gap-2 mt-6">
      <div className="flex gap-2 h-[50px] w-full items-center rounded-full bg-gray-100 pr-3 dark:bg-card">
        <p className="pl-3 text-xl text-gray-600 dark:text-gray-100 hover:cursor-pointer"
          onClick={()=>{setEmojiWindow(!emjoiWindow)}}
        >
          <MdOutlineTagFaces className='hover:cursor-pointer relative'/>
          <div className='absolute bottom-[50px]'>
            {emjoiWindow && <EmojiPicker
              onEmojiClick={onSelectEmoji}
              autoFocusSearch={false}
              theme={Theme.AUTO}
            />}
          </div>
        </p>

        <input
          placeholder="Write your message...."
          className="w-full h-full px-2 text-base text-gray-900 bg-gray-100 border-none rounded-full dark:bg-card dark:text-white"
          type="text"
          onChange={handleChange}
          value={message}
          onKeyPress={handleKeyPress}
        />

        {/* <div className="flex items-center gap-4">
          <p className="text-xl text-gray-600 dark:text-gray-100 hover:cursor-pointer">
            <MdOutlineAttachment />
          </p>

          <p className="pr-2 text-xl text-gray-600 dark:text-gray-100 hover:cursor-pointer">
            <MdImage />
          </p>
        </div> */}
      </div>

      {/* button */}
      <button
        onClick={handleSubmit}
        className="flex items-center justify-center p-3 text-base text-white transition duration-200 rounded-full linear bg-cyan-400 hover:cursor-pointer hover:bg-cyan-700 active:bg-cyan-700 dark:text-white"
      >
        <IoPaperPlane />
      </button>
    </div>
  );
};

export default Input;
