// Assets
import Card from "../card";
import DashCurveDown from "../icons/DashCurveDown";
import DashCurveUp from "../icons/DashCurveUp";
import { FaVimeo } from "react-icons/fa";
import { AiOutlineUserAdd, AiOutlineBulb, AiOutlineMessage } from "react-icons/ai";

import { MdCheckCircle, MdComment, MdPersonAddAlt1 } from "react-icons/md";

const Earn = () => {
    return (
        <Card extra={"w-full pb-[80px] pt-[30px] px-[29px] h-fit xl:h-[500px]"}>
            {/* Header */}
            <div className="mt-1">
                <p className="text-2xl font-semibold text-navy-700 dark:text-white">
                Get started with Azule
                </p>
                <p className="mt-4 text-base text-gray-600">
                    Set up an AI powered live chat customer service agent in just a few clicks.  
                    <a
                        href="https://vimeo.com/823849356/c5bc50c348" // Replace 'demo_video_id' with the actual video ID
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center ml-2 text-blue-600 hover:text-blue-500"
                    >
                        <FaVimeo className="" />
                        <span className="ml-1">  Watch our demo video</span>
                    </a>

                </p>
            </div>

            <div className="relative mt-[70px] flex w-full flex-col justify-center gap-[50px] md:flex-row">
                <div
                    className={`absolute left-[150px] hidden text-blue-500 dark:text-white md:block lg:left-[270px] xl:left-[200px] 2xl:left-[330px] 3xl:left-[180px] 4xl:left-[270px]`}
                >
                    {/* <DashCurveUp /> */}
                </div>
                <div className="absolute right-[140px] top-[60px] hidden text-blue-500 dark:text-white md:block lg:right-[270px] xl:right-[200px] 2xl:right-[330px] 3xl:right-[170px] 4xl:right-[250px]">
                    {/* <DashCurveDown /> */}
                </div>

                {/* icons */}
                <div className="flex flex-col items-center">
                    <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-blue-500 shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">

                    <AiOutlineUserAdd />
                    </div>
                    {/* text */}
                    <div className="flex flex-col items-center">
                        <p className="mt-[18px] text-xl font-bold text-gray-600 dark:text-white">
                            Create an Agent
                        </p>
                        <p className="mt-3 text-center text-base text-gray-500">
                            Start by creating an Agent. Here you will describe the purpose of your agent and any specific qualities it will have.
                        </p>

                    </div>
                </div>
                {/* icons2 */}
                <div className="mr-[14px] flex flex-col items-center">
                    <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-blue-500 shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
                    <AiOutlineBulb />
                    </div>
                    {/* text */}
                    <div className="flex flex-col items-center">
                        <p className="mt-[18px] text-xl font-bold text-gray-600 dark:text-white">
                            Train your Agent
                        </p>
                        <p className="mt-3 text-center text-base text-gray-500">
                            Upload urls and files which your agent will learn about. Add policies to guide the responses to specific topics. Test your Agent's responses for free.
                        </p>
                    </div>
                </div>
                {/* icons3 */}
                <div className="flex flex-col items-center">
                    <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-blue-500 shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
                    <AiOutlineMessage />
                    </div>
                    {/* text */}
                    <div className="flex flex-col items-center">
                        <p className="mt-[18px] text-center text-xl font-bold text-gray-600 dark:text-white">
                            Create a live chat Widget
                        </p>
                        <p className="mt-3 text-center text-base text-gray-500">
                            Subscribe to Azule and add a live chat widget to your website to start allowing your customers to interact with your agent!
                        </p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default Earn;
