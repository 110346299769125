import React, { useState } from 'react';

function HelpDeskForm({ onSubmit }) {
  const [apiUrl, setApiUrl] = useState('');
  const [email, setEmail] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [helpdesk, setHelpdesk] = useState('gorgias');
  const [errors, setErrors] = useState({ apiUrl: '', email: '', apiKey: '' });
  const [months, setMonths] = useState(4);
  const [enableCheckbox, setEnableCheckbox] = useState(true);
  const handleMonthsChange = (e) => {
    setMonths(parseInt(e.target.value, 10));
  };
  const handleCheckboxChange = (e) => {
    setEnableCheckbox(e.target.checked);
  };
  const handleApiUrlChange = (e) => {
    setApiUrl(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, apiUrl: '' }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }
  };

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, apiKey: '' }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      apiUrl: apiUrl ? '' : 'API URL is required.',
      email: email ? '' : 'Email is required.',
      apiKey: apiKey ? '' : 'API Key is required.',
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== '');

    if (!hasErrors) {
      const data = {
        apiUrl,
        email,
        apiKey,
        helpdesk,
        months,
        enableCheckbox, 
      };
      onSubmit(data);
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Enable Gorgias Integration</label>
        <input
          type="checkbox"
          className="text-gray-700 mt-1 block rounded-md border-gray-300 shadow-sm"
          checked={enableCheckbox}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Helpdesk</label>
        <select
          className="text-gray-700 mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          value={helpdesk}
          onChange={(e) => setHelpdesk(e.target.value)}
        >
          <option value="gorgias">Gorgias</option>
          {/* <option disabled value="reamaze">Reamaze</option> */}
          
          {/* <option value="extra">Extra</option> */}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Brand Name</label>
        <div className="flex">
          
          <input
            type="text"
            className={`text-gray-700 mt-1 block w-full rounded-l-md border-gray-300 px-3 py-2 shadow-sm ${errors.apiUrl ? 'border-red-500' : ''}`}
            value={apiUrl}
            onChange={handleApiUrlChange}
          />
          <span style={{marginTop:"4px"}} className="border-t border-b border-r border-gray-300 bg-gray-100 text-gray-700 px-3 py-2 rounded-r-md">gorgias.com</span>

        </div>
        {errors.apiUrl && <p className="text-red-500 text-xs italic">{errors.apiUrl}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Username (Email)</label>
        <input
          type="email"
          className={`text-gray-700 mt-1 block w-full rounded-md border-gray-300 shadow-sm ${errors.email ? 'border-red-500' : ''}`}
          value={email}
          onChange={handleEmailChange}
        />
        {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Password (API Key)</label>
        <input
          type="password"
          className={`text-gray-700 mt-1 block w-full rounded-md border-gray-300 shadow-sm ${errors.apiKey ? 'border-red-500' : ''}`}
          value={apiKey}
          onChange={handleApiKeyChange}
        />
        {errors.apiKey && <p className="text-red-500 text-xs italic">{errors.apiKey}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Months</label>
        <select
          className="text-gray-700 mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          value={months}
          onChange={handleMonthsChange}
        >
          {[...Array(9).keys()].map((_, i) => (
            <option key={i} value={i + 4}>
              {i + 4} months
            </option>
          ))}
        </select>
      </div>
      <button
        type="submit"
        className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
      >
        Submit
      </button>
    </form>
  );
}

export default HelpDeskForm;
