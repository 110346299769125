import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

const Message = ({ message }) => {
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
  }, [navigate]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);
  const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formatter = new Intl.DateTimeFormat(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
    return formatter.format(date);
  };
  return (
    <>
      
    </>

  );
};

export default Message;
