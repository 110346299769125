import React, { useState, useEffect } from 'react';
import Search from './Search';
import RulesetCard from './RulesetCard';
import { FiPlus, FiX } from 'react-icons/fi';
import { RiSave3Fill } from 'react-icons/ri';
import Modal from './Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheck } from 'react-icons/fa';
import { showToast } from '../../../elements/ToastManager';
import LoadingScreen from 'react-loading-screen';
import { useAuth } from '../../../../context/AuthContext';
function RulesetList({ selectedCategory, onUpdateRuleset, categories, chatbotId, chatBotName, handleRefresh, refreshCategories, actions = [] }) {
  const { user } = useAuth()
  const [modalOpen, setModalOpen] = useState(false);
  const [newRuleset, setNewRuleset] = useState({ name: '', text: '', state: 'active'});
  const [rulesets, setRulesets] = useState([]);
  const [errors, setErrors] = useState({ name: '', text: '', state: '' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRulesets();
  }, [selectedCategory]);

  const fetchRulesets = async () => {
    console.log("called")
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/policy/list_policies`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          agentId: chatbotId, // Replace this with the actual chatbotId
          categoryName: selectedCategory,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("ruleset:" + data)
        setRulesets(data);
        setIsLoading(false);
      } else {
        console.error('Error fetching rulesets:', response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching rulesets:', error);
      setIsLoading(false);
    }
  };
  const CustomTickIcon = () => (
    <FaCheck size={20} color="blue" className="mr-2" />
  );
  const validateForm = () => {
    let isValid = true;
    let tempErrors = { ...errors };

    // if (newRuleset.name.trim() === '') {
    //   tempErrors.name = 'Category Name is required.';
    //   isValid = false;
    // } else {
    //   tempErrors.name = '';
    // }

    if (newRuleset.text.trim() === '') {
      tempErrors.text = 'Statement is required.';
      isValid = false;
    } else {
      tempErrors.text = '';
    }

    setErrors(tempErrors);
    return isValid;
  };


  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewRuleset({ ...newRuleset, [name]: value });
  };
  const handleActionChange = (event) => {
    const { name, value } = event.target;
    setNewRuleset({ ...newRuleset, [name]: actions[value] });
  };

  const handleSaveNewRuleset = async () => {
    if (validateForm()) {
      console.log(newRuleset)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}` },
        body: JSON.stringify({
          agentId: chatbotId, // Replace with your agent ID
          agentName: chatBotName, // Replace with your agent name
          categoryName: selectedCategory,
          text: newRuleset.text,
          state: newRuleset.state,
        }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/policy/add_policy`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Policy saved:', data);
        handleModalClose();
        showToast('success', 'Policy saved. Updating policy list in a short while', 4);
        var temp = {
          key: data.key,
          state: data.state,
          text: data.Text,
        }
        // Add the newly added ruleset to the existing rulesets state
        setRulesets([...rulesets, temp]);

        // Fetch updated rulesets from the server
        // fetchRulesets();

      } catch (error) {
        showToast('error', 'Error saving policy. Please try again', 4)
        console.error('Error saving policy:', error);
      }
    }
  };


  return (
    <>
      <LoadingScreen
        loading={isLoading}
        bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
        spinnerColor="rgb(9 199 225)"
        textColor="#676767"
        className="w-full h-full flex items-center justify-center"
      ></LoadingScreen>

      {!isLoading &&
        <div className="dark p-4" style={{ maxHeight: 'calc(100vh - 85px)', overflowY: 'auto' }}>
          <Search />
          {selectedCategory !== 'All' && selectedCategory !== null && (
            <button
              className="flex items-center justify-center text-sm text-dark my-2 bg-gray-300 py-2 px-4 rounded hover:bg-gray-400 transition duration-200 ease-in-out"
              onClick={handleModalOpen}
            >
              <FiPlus className="mr-1" />
              Add New
            </button>
          )}
          {rulesets.map((ruleset, index) => (
            <RulesetCard key={index} ruleset={ruleset} selectedCategory={selectedCategory} chatbotId={chatbotId} chatBotName={chatBotName} index={index} onUpdateState={onUpdateRuleset} />
          ))}
          <Modal isOpen={modalOpen} onClose={handleModalClose}>
            <div className="p-4 rounded">
              <h2 className="text-2xl mb-4 text-gray-700">Add Policy</h2>
              <form>
                <label className="block mb-2 text-gray-700">
                  Category Name
                  <select
                    className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                    name="name"
                    value={newRuleset.name}
                    onChange={handleInputChange}
                  >
                    <option value={selectedCategory}>{selectedCategory}</option>
                  </select>
                  {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                </label>
                <label className="block mb-2 text-gray-700">
                  Statement
                  <textarea
                    className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                    name="text"
                    value={newRuleset.text}
                    onChange={handleInputChange}
                  />
                  {errors.text && <p className="text-red-500 text-xs mt-1">{errors.text}</p>}
                </label>

                <label className="block mb-4 text-gray-700">
                  State
                  <select
                    className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                    name="state"
                    value={newRuleset.state}
                    onChange={handleInputChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </label>
                {actions.length > 0 ? <label className="block mb-4 text-gray-700">
                  Action
                  <select
                    className="block w-full mt-1 bg-transparent text-gray-700 border border-gray-500 rounded"
                    name="action"
                    value={newRuleset.action}
                    onChange={handleActionChange}
                  >
                     <option value="" disabled selected>Select your option</option>
                    {actions.map((action,index)=><option value={index}>{action.label}</option>)}
                  </select>
                </label> : ""}
                <div className="flex items-center ">

                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 mr-2 rounded flex items-center"
                    onClick={handleModalClose}
                  >
                    <FiX className="mr-1" />
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-green-500 text-white px-4 py-2  rounded flex items-center"
                    onClick={handleSaveNewRuleset}
                  >
                    <RiSave3Fill className="mr-1" />
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      }
    </>
  );
}

export default RulesetList;