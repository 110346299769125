import React, { useState, useEffect } from 'react';
import { FiEdit, FiSave, FiX, FiTrash2 } from 'react-icons/fi';
import Modal from './Modal';
import { useAuth } from '../../../../context/AuthContext';
function RulesetCard({ ruleset, index, onUpdateState, chatbotId, chatBotName,setRulesets,rulesets }) {
  const {user} = useAuth()
  const [checked, setChecked] = useState(ruleset.state === 'active');
  const [editing, setEditing] = useState(false);
  const [tempRules, setTempRules] = useState(ruleset.text);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    setChecked(ruleset.state === 'active');
  }, [ruleset]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleDelete = () => {
   
    toggleDeleteModal();
  };
  const handleDeleteConfirmation = async () => {
    console.log(chatBotName, ruleset.key, chatbotId);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,  "Authorization":`Bearer ${user.token}`,},
      body: JSON.stringify({
        agentName: chatBotName,
        key: ruleset.key,
        agentId: chatbotId
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/description/delete_policy`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log( data);
      handleDelete();
      setShowDeleteModal(false);
      const filtered_rule_set = rulesets?.filter(ruleset=>ruleset.key!=data.key)
      setRulesets(filtered_rule_set)

    } catch (error) {
      console.error('Error deleting policy:', error);
    }
  };
  useEffect(() => {
    setTempRules(ruleset.text);
  }, [ruleset]);

  const handleCheckboxChange = async (event) => {
    const newState = event.target.checked ? 'active' : 'inactive';
    setChecked(event.target.checked);
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',  "Authorization":`Bearer ${user.token}` },
      body: JSON.stringify({
        key: ruleset.key,
        state: newState,
        agentId: chatbotId,
      }),
    };
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/description/update_policy_state`,
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Policy state updated:', data);
    } catch (error) {
      console.error('Error updating policy state:', error);
    }
  };

  const toggleEditing = () => {
    if (editing) {
      setTempRules(ruleset.text);
    }
    setEditing(!editing);
  };

  const handleRulesChange = (event) => {
    setTempRules(event.target.value);
  };

  const handleSave = async () => {
    const updatedRuleset = { ...ruleset, text: tempRules };
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',  "Authorization":`Bearer ${user.token}` },
      body: JSON.stringify({
        key: updatedRuleset.key,
        agentId: chatbotId,
        agentName: chatBotName,
        text: updatedRuleset.text,
        state: updatedRuleset.state,
      }),
    };
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/description/update_policy`,
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Policy updated:', data);
  

      toggleEditing();
    } catch (error) {
      console.error('Error updating policy:', error);
    }
  };
  return (
    <div
      // style={{ backgroundColor: 'rgb(43 52 59)' }}
      className="shadow-md rounded-md p-3 flex items-center justify-between w-full mx-auto my-2 text-gray-700"
    >
      <Modal isOpen={showDeleteModal} onClose={toggleDeleteModal}>
        <div className="p-4 rounded">
          <h2 className="text-2xl mb-4 text-gray-700">Confirm Deletion</h2>
          <p className='text-gray-700'>Are you sure you want to delete this policy?</p>
          <div className="flex items-center mt-4 ">
            <button
              type="button"
              className="bg-red-500 text-white px-4 py-2 mr-2 rounded flex items-center"
              onClick={handleDeleteConfirmation}
            >
              <FiTrash2 className="mr-1" />
              Delete
            </button>
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-2 rounded flex items-center"
              onClick={toggleDeleteModal}
            >
              <FiX className="mr-1" />
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex-1 mr-3">
        <h3 className="text-base font-medium inline-flex items-center">
          {ruleset.action}
          {!editing ? (
            <>
              <FiEdit
                className="ml-2 text-sm cursor-pointer hover:text-blue-300 transition duration-150 ease-in-out"
                onClick={toggleEditing}
              />
              <FiTrash2
                className="ml-2 text-sm cursor-pointer text-red-500 hover:text-red-700 transition duration-150 ease-in-out"
                onClick={handleDelete}
              />
            </>
          ) : (
            <>
              <FiSave className="ml-2 text-sm cursor-pointer hover:text-blue-300 transition duration-150 ease-in-out" onClick={handleSave} />
              <FiX className="ml-2 text-sm cursor-pointer hover:text-blue-300 transition duration-150 ease-in-out" onClick={toggleEditing} />
            </>
          )}
        </h3>
        {!editing ? (
          <p className="text-sm">{tempRules}</p>
        ) : (
          <textarea
            className="bg-transparent w-full text-gray-700 resize-none text-sm min-w-3/4 h-24"
            value={tempRules}
            onChange={handleRulesChange}
          />
        )}
      </div>
      <div className="flex-shrink-0">
        <label htmlFor={`checkbox-${index}`} className="cursor-pointer inline-flex items-center">
          <input
            type="checkbox"
            id={`checkbox-${index}`}
            className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-900 dark:border-gray-500 mr-2"
            checked={checked}
            onChange={handleCheckboxChange}
          />
        </label>
      </div>
    </div>
  );
}

export default RulesetCard;
