import React, { useState, useEffect } from 'react';
import { useCategory } from './CategoryContext'; // Import useCategory
import RulesetList from './RulesetList';
import LoadingScreen from 'react-loading-screen';
import { useAuth } from '../../../../context/AuthContext';

const Rulesets = ({ chatbotId, chatBotName,actions=[] }) => {
  const {user} = useAuth()
  const { selectedCategory } = useCategory(); // Use useCategory
  const [rulesets, setRulesets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
    // Set interval for fetching categories every 60 seconds
    const fetchInterval = setInterval(() => {
      fetchCategories("interval");
    }, 30000);

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(fetchInterval);
    };
  }, [chatbotId, selectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/policy/category/list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization":`Bearer ${user.token}`,
        },
        body: JSON.stringify({ agentId: chatbotId }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        setCategories(data);
      } else {
        console.error('Error fetching categories:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };


  return (
    <div>
      <h1 className="text-2xl mt-2 ml-6 text-light">Policies</h1>
      {isLoading && <LoadingScreen
        loading={isLoading}
        bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
        spinnerColor=" rgb(9 199 225)"
        textColor="#676767"
      ></LoadingScreen>
      }
      {!isLoading &&
        <RulesetList
          selectedCategory={selectedCategory}
          rulesets={rulesets}
          categories={categories}
          chatbotId={chatbotId}
          chatBotName={chatBotName}
          refreshCategories={fetchCategories}
          // actions={actions}
        />
      }

    </div>
  );
};

export default Rulesets;
