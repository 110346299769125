import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';

function URLInput({ onSubmit }) {
  const [urls, setUrls] = useState(['']);
  const [categories, setCategories] = useState(['']);
  const [errors, setErrors] = useState({});



  const handleAddNewURL = () => {
    if (urls.length < 1) {
      setUrls([...urls, '']);
      setErrors({ ...errors, [urls.length]: '' });
    }
  };


  const handleCategoryChange = (e, index) => {
    const newCategories = [...categories];
    newCategories[index] = e.target.value;
    setCategories(newCategories);

    if (e.target.value.trim() === '') {
      setErrors({ ...errors, [`category${index}`]: 'Category is required' });
    } else {
      setErrors({ ...errors, [`category${index}`]: '' });
    }
  };

  const handleURLChange = (e, index) => {
    const newUrls = [...urls];
    newUrls[index] = e.target.value;
    setUrls(newUrls);

    if (!validateURL(e.target.value)) {
      setErrors({ ...errors, [index]: 'Invalid URL' });
    } else {
      setErrors({ ...errors, [index]: '' });
    }
  };

  const removeURLField = (index) => {
    const newUrls = urls.filter((_, idx) => idx !== index);
    setUrls(newUrls);
  };

  const handleSubmit = () => {
    const hasEmptyFields = urls.some((url) => url.trim() === '') || categories.some((category) => category.trim() === '');
    const hasErrors = Object.values(errors).some((error) => error !== '');

    if (!hasErrors && !hasEmptyFields) {
      const data = {
        urls,
        categories,
      };
      onSubmit(data);
    }
  };

  const validateURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <>
      {
        urls.map((url, index) => (
          <div key={index} className="mb-4">
            <div className="flex items-center mb-2">
              <label className="block text-gray-700 mr-6">URL {index + 1}</label>
            <input
              type="text"
              required
              className={`mt-1 block w-75 rounded-md border-gray-300 shadow-sm text-gray-700 ${errors[index] ? 'border-red-500' : ''}`}
              placeholder={`Enter URL ${index + 1}`}
              value={url}
              onChange={(e) => handleURLChange(e, index)}
            />
            <button
              className="text-red-600 ml-6"
              onClick={() => removeURLField(index)}
            >
              <MdClose />
            </button>
            {errors[index] && (
              <p className="text-red-500 text-xs italic">{errors[index]}</p>
            )}
          </div>
            <div className="flex items-center">
              <label className="block text-gray-700 mr-6">Category </label>
              <input
                type="text"
                required
                className={`mt-1 block w-75 rounded-md border-gray-300 shadow-sm text-gray-700 ${errors[`category${index}`] ? 'border-red-500' : ''}`}
                placeholder={`Enter category`}
                value={categories[index] || ''}
                onChange={(e) => handleCategoryChange(e, index)}
              />
              {errors[`category${index}`] && (
                <p className="text-red-500 text-xs italic ml-2">{errors[`category${index}`]}</p>
              )}
            </div>
          </div>
          
        ))
      }
      {/* {
        urls.length < 5 && (
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-8"
            onClick={handleAddNewURL}
          >
            Add New URL
          </button>
        )
      } */}
      <button
        className=" bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </>
  );
}

export default URLInput;
