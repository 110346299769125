// CategoryContext.js
import { createContext, useContext, useState } from 'react';

const ActionContext = createContext();

export const useAction = () => useContext(ActionContext);

export const ActionProvider = ({ children }) => {
  const [selectedAction, setSelectedAction] = useState('All');

  const value = {
    selectedAction,
    setSelectedAction,
  };

  return <ActionContext.Provider value={value}>{children}</ActionContext.Provider>;
};
