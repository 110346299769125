import React, { useContext, useEffect, useState } from "react";
import Message from "./Message";

const Messages = ({ ws }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!ws) {
      return;
    }
    setMessages([])
    const handleMessage = (event) => {
      setMessages((prevMessages) => [...prevMessages, JSON.parse(event.data)]);
    };
    ws.addEventListener("message", handleMessage);

    return () => {
      ws.removeEventListener("message", handleMessage);
    };
  }, [ws]);

  
  return (
    <div className="messages">
      {messages.map((m, index) => (
        <Message key={index} message={m} />
      ))}
    </div>
  );
};

export default Messages;
