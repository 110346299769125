import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { BsPencilSquare } from 'react-icons/bs';
import RulesetCard from './RulesetCard';

const Search = ({ rulesets }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredRulesets, setFilteredRulesets] = useState([]);

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      const filtered = rulesets.filter((ruleset) =>
        ruleset.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredRulesets(filtered);
    }
  };

  return (
    <>
      <div className="flex flex-col items-start w-full gap-2">
        <div className="flex items-center w-full gap-2">
          <div className="flex items-center w-full h-10 text-gray-900 bg-gray-100 rounded-full  dark:text-white">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="w-4 h-4 text-gray-400" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              className="block h-full w-full rounded-full bg-gray-100 text-sm text-gray-900 outline-none placeholder:!text-gray-400  dark:text-white sm:w-fit border-none"
              onKeyDown={handleKey}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </div>

          <button className="flex items-center justify-center rounded-full bg-cyan-400 p-[10px] text-base font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700 dark:bg-cyan-600 dark:hover:bg-cyan-800 dark:active:opacity-90">
          <FiSearch className="w-4 h-4 text-gray-400" />
          </button>
        </div>

        <div className="mt-4">
          {filteredRulesets.map((ruleset, index) => (
            <RulesetCard key={index} ruleset={ruleset} index={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Search;
