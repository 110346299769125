import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
// import LoadingScreen from 'react-loading-screen';
import { getPrices } from './StripeAPI';
import { useAuth } from '../../../context/AuthContext'; // Import AuthContext
import { CheckCircleIcon } from '@heroicons/react/solid';
import { MdCheckCircle } from 'react-icons/md';
import { toast } from 'react-toastify';
import { showToast } from '../../elements/ToastManager'; // Import showToast function
const Billing = () => {
    const [loading, setLoading] = useState(true);
    const [prices, setPrices] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const navigate = useNavigate();
    const { user } = useAuth(); // Use AuthContext
    const [hasSubscription, setHasSubscription] = useState(false);
    const [subscription, setSubscription] = useState({});
    //   useEffect(() => {
    //     const fetchData = async () => {
    //       const data = await getPrices();
    //       setPrices(data);
    //       setLoading(false);
    //     };

    //     fetchData();
    //   }, []);
    useEffect(() => {
        const checkActiveSubscription = async () => {
            setLoading(true)
            try {
                if (user.token) {
                    const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/stripe/ActiveSubscription`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        if (data && data.length > 0) {
                            setHasSubscription(true);
                            setSubscription(data[0])

                        }
                    } else {
                        // Handle error
                        // setLoading(false)
                    }
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                console.error('Error:', error);
            }
        };

        checkActiveSubscription();
    }, [user]);
    const handleSubscribe = async (priceId) => {
        try {
            if (user.token) {
                const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/stripe/checkout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    },
                    body: JSON.stringify({}),
                });
                if (response.ok) {
                    setLoading(false)
                    const data = await response.json();
                    const url = data.url;
                    window.location.href = data.url;
                } else {

                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleFreeTrailSubscribe = async (priceId) => {
        try {
            if (user.token) {
                const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/stripe/freeTrail`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    },
                    body: JSON.stringify({}),
                });
                if (response.ok) {
                    setLoading(false)
                    const data = await response.json();
                    const url = data.url;
                    showToast('success', 'Free Trial Activated Successfully', 5);
                    navigate('/admin/widgets')

                } else {

                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        if (user) { // Use currentUser from AuthContext
            // console.log(currentUser);
            //   setName(user.user.first_name);
            // alert(user)
            setCurrentUser(user);
            //   getCards();
        } else {
            navigate('/login');
        }
    }, [currentUser]);
    useEffect(() => {
        const dummyData = [
            // {
            //     id: '1',
            //     nickname: 'Free Plan',
            //     name: 'free_plan',
            //     monthly_price: 0,
            //     features: ['Free for first 50 messages'],
            // },
            {
                id: '2',
                nickname: 'Basic Plan',
                name: 'basic_plan',
                unit_amount: 0.02,
                monthly_price: 19,
                features: ['Pay as you go', 'Billed per message', 'Billed monthly', 'Pay only for the messages your agent responds to', 'Unlimited training messages'],
                trail_features: ['Free first 50 messages', 'No card required'],

            },
            // {
            //     id: 'price_2',
            //     nickname: 'Pro Plan',
            //     unit_amount: 1999,
            //     features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
            // },
            // {
            //     id: 'price_3',
            //     nickname: 'Enterprise Plan',
            //     unit_amount: 2999,
            //     features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
            // },
        ];

        setPrices(dummyData);
        // setLoading(false);
    }, []);

    return (
        <>


            <main className='min-h-screen dark:bg-[#1E272E]'>
                {loading ? (
                    <LoadingScreen
                        loading={loading}
                        bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
                        spinnerColor="rgb(9 199 225)"
                        textColor="#676767"
                    ></LoadingScreen>
                ) : (
                    <section>
                        <div className="pricing-table flex flex-wrap justify-center items-center gap-6 py-12 px-4">
                            {prices.map((price) => (
                                <div key={price.id} className="pricing-card dark:bg-gray-700 bg-white rounded-xl shadow-lg w-full md:w-80">
                                    <div className="pricing-card-header dark:text-white text-gray-700 py-6 px-8 rounded-t-xl">
                                        <h3 className="text-2xl font-semibold mb-2">{price.nickname}</h3>
                                        <h4 className="text-xl font-bold">
                                            ${(price?.monthly_price)?.toFixed(2)}
                                            <span className="dark:text-gray-300 text-gray-600">/month </span>
                                        </h4>
                                        {price.unit_amount ? <>
                                            <span className="dark:text-gray-300 text-gray-600">For first 1000 messages</span>
                                            <p>+</p>
                                            <h4 className="text-l font-bold">
                                                ${(price?.unit_amount)?.toFixed(2)}
                                                <span className="dark:text-gray-300 text-gray-600">/message </span>
                                            </h4>
                                            <span className="dark:text-gray-300 text-gray-600">1000+ messages</span>
                                        </>
                                            : ""}
                                        {/* {hasSubscription ? (
                                            <div className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                                                <b>You have an active subscription</b>
                                            </div>
                                        ) : null} */}
                                    </div>
                                    <div className="pricing-card-body dark:text-gray-300 text-gray-600 px-10">
                                        <ul className="space-y-4">
                                            {price.features.map((feature, index) => (
                                                <li key={index} className="flex items-center space-x-2">
                                                    <div>
                                                        <CheckCircleIcon className="h-5 w-5 text-blue-500 dark:text-green-400" />
                                                    </div>
                                                    <div style={{ textAlign: "left" }}>
                                                        <span className="text-sm font-medium">{feature}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <h3 className="text-2xl font-semibold" style={{ "textAlign": "center" }}>Free Trial Features</h3>

                                    <div className="pricing-card-body dark:text-gray-300 text-gray-600 px-10">
                                        <ul className="space-y-4">
                                            {price.trail_features.map((feature, index) => (
                                                <li key={index} className="flex items-center space-x-2">
                                                    <div>
                                                        <CheckCircleIcon className="h-5 w-5 text-blue-500 dark:text-green-400" />
                                                    </div>
                                                    <div style={{ textAlign: "left" }}>
                                                        <span className="text-sm font-medium">{feature}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        {subscription?.plan !== 'basic_plan' ? (
                                            <div style={{
                                                "display": "flex",
                                                "width": "100%",
                                                "justifyContent": "space-evenly"
                                            }}>
                                                {subscription?.plan !== 'basic_plan' ? <button
                                                    className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                                    onClick={() => handleSubscribe(price.id)}
                                                >
                                                    Subscribe
                                                </button> : ""}
                                                {!subscription?.plan ? <button
                                                    className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                                    onClick={() => handleFreeTrailSubscribe(price.id)}
                                                >
                                                    Start Free Trial
                                                </button> : ""}
                                            </div>
                                        ) : ""}
                                       
                                    </div>
                                    <div className="flex justify-center mt-4">
                                    
                                    {subscription?.plan?<button
                                            className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                        // onClick={handleManageSubscription}
                                        >
                                            {subscription?.plan == 'basic_plan' ? "Your subscription is active" : `Your free trial is active (${50-subscription.messages_count>0? `${50-subscription.messages_count} messages left`:"0 Messages left"}) ` }
                                        </button>
                                        :""}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                )}
            </main>

        </>
    );
};

export default Billing;

// const Login = () => {
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(true)
//     const [userId, setUserId] = useState()

//     useEffect(() => {
//         onAuthStateChanged(auth, (user) => {
//             setLoading(false)
//             if (user) {
//                 setUserId(user.uid)

//             } else {
//                 navigate('/login')
//             }
//         });

//     }, [])

//     return (
//         <>
//             <main >
//                 <LoadingScreen
//                     loading={loading}
//                     bgColor="#1e272e"
//                     spinnerColor=" rgb(9 199 225)"
//                     textColor="#676767"
//                 ></LoadingScreen>
//                 <section>

// <stripe-pricing-table pricing-table-id="prctbl_1Mn3ZkC9WxOJgRdu4OSwNQt9"
// publishable-key="pk_test_51MlnzwC9WxOJgRduOiW22H0DBeDrNChfEmr6qzRPnGFji6GzXzK4z9lBxxButXjPTyw5dI53Aba4UDlvkV9jH6wz00EExcNBDn">
// </stripe-pricing-table>
//                 </section>
//             </main>
//         </>
//     )
// }

// export default Login