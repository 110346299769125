import React, { useState, useContext, useEffect } from 'react';
import Text from '../components/elements/Text';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { BsGoogle } from 'react-icons/bs';
import { useAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import { useSearchParams } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
const EmailVericiation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [firstName, setFirstName] = useState('');
    let [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState(location?.state?.email);
    useEffect(() => {
        const location_email = location?.state?.email
        if (!location_email) {
            navigate('/login')
        }
    }, [])

    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingResend, setIsLoadingResend] = useState(false)
    const onFirstNameChange = (e) => {
        setFirstName(e.target.value);
        setIsFirstNameValid(e.target.value.trim().length > 0);
    };

    const resendEmail = async (e) => {
        setIsLoadingResend(true)
        setTimeout(() => setIsLoadingResend(false), 30000);
        var response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/resendVerifyEmail`, {
            method: "POST",
            body: JSON.stringify({ "email": email })
        })
        response = await response.json()
        toast.success('Verification email sent.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
        console.log(response)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/verifyEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                token: firstName,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            setIsLoading(false)

            // navigate('/login'); // Redirect to the login page
            toast.success('Email verification successfull!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            if (data.domain) {
                const delay = ms => new Promise(res => setTimeout(res, ms));
                await delay(1000)
                window.location.href = data.domain
            }
            else
                navigate('/login')
        } else {
            toast.error('Please enter a valid code!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            setIsLoading(false)

            setIsLogin(false);
        }
    };
    ;
    // }


    return (
        <main >
            <section>
                <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8">
                        <div>
                            <div className="py-2 px-16 text-center">
                                <NavLink to="/admin/dashboard">
                                    <img src={process.env.REACT_APP_LOGO} alt="ShopAgent Logo" className="text-3xl font-bold uppercase no-underline dark:text-white" />
                                </NavLink>
                            </div>

                            <h2 className="text-white text-center text-base  tracking-tight text-gray-900">
                                Verify your email address.
                            </h2>
                            <p className="text-light-gray text-center text-base  tracking-tight text-gray-900">
                                Please enter the code you received in at your email.
                            </p>
                            {!isLogin && (
                                <p className="text-red-500 text-center mt-2 validation">Invalid code entered!</p>
                            )}
                        </div>


                        <form onSubmit={onSubmit} className="mt-8 space-y-6" >
                            <div className=" space-y-6 rounded-md shadow-sm">
                                <div>
                                    <label htmlFor="first-name" className="sr-only">
                                        Code
                                    </label>
                                    <input
                                        id="first-name"
                                        label="Code"
                                        value={firstName}
                                        onChange={onFirstNameChange}
                                        name="code"
                                        type="text"
                                        required
                                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Enter code"
                                    />
                                </div>
                                {!isFirstNameValid && (
                                    <span className="text-red-500 text-xs mt-1 validation">
                                        Code field can't be empty.
                                    </span>
                                )}

                            </div>
                            <div>
                                <button type="button"
                                    disabled={isLoadingResend}
                                    className="text-sm text-right underline text-tertiary" style={{ "float": "right" }} onClick={() => resendEmail()}>
                                    {isLoadingResend ? "Retry in 30 seconds" : "Resend confirmation email"}
                                </button>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin" style={{ "margin": "auto 0" }}></i> : ""}<span style={{ "paddingLeft": "10px" }}>Submit</span>

                                </button>
                            </div>

                        </form>


                        <p className="text-sm text-white text-center">
                            Go back ? {' '}
                            <NavLink to="/login" className="underline text-tertiary">
                                Sign in
                            </NavLink>
                        </p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default EmailVericiation