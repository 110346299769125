import Card from '../card';

import Messages from './Messages';
import Input from './Input';
import Header from './Header';

import { MdChevronLeft } from 'react-icons/md';

function SingleChat({ ws, chat, open, onClose, roomId }) {
  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${
        open
          ? 'translate-x-[20px] lg:translate-x-[0px]'
          : '-translate-x-[120%] lg:translate-x-[0px]'
      }`}
    >
      <Card extra={'w-full h-full p-4'}>
        {/* header */}
        {/* <Header /> */}
        <div onClick={onClose} className={'block lg:hidden'}>
          <MdChevronLeft size={36} className="text-gray-500 dark:text-white" />
        </div>

        {/* message content */}
        {chat ? (
          <div className="relative h-full rounded-xl overflow-hidden">
            <Messages ws={ws} roomId={roomId} />

            <Input ws={ws} />
          </div>
        ) : (
          <div className="my-auto mx-auto font-semibold text-4xl text-gray-400">
            Start a new chat
          </div>
        )}
      </Card>
    </div>
  );
}

export default SingleChat;
