import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext'; // Import AuthContext
import { CategoryProvider } from './ruleset/CategoryContext';
import { ActionProvider } from './actions/ActionContext';
import '../../../styles.css';
import { MdModelTraining, MdPolicy, MdBuild } from 'react-icons/md';
import Card from '../card';
import ChatsList from './ChatsList';
import SingleChat from './SingleChat';
import Categories from './ruleset/Categories';
import Actions from './actions/Actions';
import Search from './ruleset/Search';
import Rulesets from './ruleset/Rulesets';
import RulesetsAction from './actions/Rulesets';
import LoadingScreen from 'react-loading-screen';
import { useChatBots } from '../../../store/context/chatbotcontext';

const LiveChat = ({ }) => {
  const [name, setName] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [ws, setWs] = useState(null);
  const [chat, setChat] = useState(false);
  const [cards, setCards] = useState({});
  const [botId, setBotId] = useState(null);
  const [botName, setBotName] = useState(null);
  const [roomId, setRoomId] = useState(0)
  const [selectedTab, setSelectedTab] = useState('training');
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([])
  const { chatbots,chatBotLoading } = useChatBots()
  const xyz = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { user } = useAuth(); // Use AuthContext

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (xyz.state?.value) {
      setBotId(xyz.state?.value);
      setBotName(xyz.state?.name);
    } else if (cards && Object.keys(cards).length > 0) {
      const firstBotId = Object.keys(cards)[0];
      setBotId(cards[firstBotId]?.id);
      setBotName(cards[firstBotId]?.name);
    }
  }, [xyz.state, cards]);

  const handleSelect = (e) => {
    // console.log(e.target.value)
    setBotId(e.target.value);
  };
  useEffect(() => {

    if (user) { // Use currentUser from AuthContext
      setName(user.user.first_name);
      setCurrentUser(user);
    } else {
      navigate('/login');
    }
  }, [currentUser]);
  useEffect(() => {
    setCards(chatbots || {});
    if (chatbots && Object.keys(chatbots).length > 0) {
      const firstBotId = Object.keys(chatbots)[0];
      setBotId(chatbots[firstBotId]?.id);
      setBotName(chatbots[firstBotId]?.name);
    }
  
  }, [chatbots])
  useEffect(()=>{
    setIsLoading(chatBotLoading);
    
  },[chatBotLoading])
  const fetchActions = async () => {
    const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/agent/actions?agentId=${botId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${user.token}`,
      },

    });
    setActions(await response.json() || [])
  }

  useEffect(async () => {
    if (botId && selectedTab === 'actions') {
      await fetchActions()
    }

  }, [botId, selectedTab])


  const Spinner = () => (
    <div className="w-16 h-16 border-t-2 border-blue-500 rounded-full animate-spin mx-auto mt-10"></div>
  );
  return (
    <>
      {isLoading && <LoadingScreen
        loading={isLoading}
        bgColor={"bg-[#ffffff9e] dark:bg-sidebar/50"}
        spinnerColor=" rgb(9 199 225)"
        textColor="#676767"
      ></LoadingScreen>
      }
      {currentUser?.user?.id && (
        <CategoryProvider>
          <ActionProvider>
            <div style={{ marginTop: '-110px' }} className="h-screen flex w-full grid-cols-10 flex-col gap-2 h-[96.5vh] lg:flex-row">
              {/* Sidebar for Chat List */}
              <div className="w-full lg:w-[35%]">
                <Card extra={'w-full h-full p-4'}>

                  <div className="flex items-center justify-center">
                    {/* <h1 className="mb-3 text-xl font-semibold text-gray-900 font-dm dark:text-white">
                    Your Chat Bots
                  </h1>                 */}
                    <div className="flex items-center justify-center">
                      <select onChange={handleSelect} className="mb-3 mr-2 w-full rounded-full border flex items-center justify-center text-sm font-semibold border-none text-gray-600 hover:cursor-pointer dark:!bg-sidebar dark:text-white">

                        {Object.keys(cards).map(id => (
                          <option value={cards[id]?.id} key={id}>{cards[id]?.name}</option>
                        ))}
                      </select>
                    </div>

                  </div>
                  <hr></hr>
                  <div className="flex justify-around my-3">
                    <div className="flex flex-col items-center">
                      <button
                        className={`border-none text-sm font-semibold focus:outline-none ${selectedTab === 'training' ? 'text-blue-600 dark:text-blue-300' : 'text-gray-800 dark:text-gray-600'}`}
                        onClick={() => handleTabChange('training')}
                      >
                        <div className="flex flex-col items-center">
                          <MdModelTraining className="text-xl" />
                          Training
                        </div>
                      </button>
                      {selectedTab === 'training' && <div className="w-full h-1 mt-1 bg-blue-600 dark:bg-blue-300"></div>}
                    </div>
                    <div className="flex flex-col items-center">
                      <button
                        className={`border-none text-sm font-semibold focus:outline-none ${selectedTab === 'policy' ? 'text-blue-600 dark:text-blue-300' : 'text-gray-800 dark:text-gray-600'}`}
                        onClick={() => handleTabChange('policy')}
                      >
                        <div className="flex flex-col items-center">
                          <MdPolicy className="text-xl" />
                          Policy
                        </div>
                      </button>
                      {selectedTab === 'policy' && <div className="w-full h-1 mt-1 bg-blue-600 dark:bg-blue-300"></div>}
                    </div>
                    <div className="flex flex-col items-center">
                      <button
                        className={`border-none text-sm  font-semibold focus:outline-none ${selectedTab === 'actions' ? 'text-blue-600 dark:text-blue-300' : 'text-gray-800 dark:text-gray-600'}`}
                        onClick={() => handleTabChange('actions')}

                      >
                        <div className="flex flex-col items-center">
                          <MdBuild className="text-xl" />
                          Actions
                        </div>
                      </button>
                      {selectedTab === 'actions' && <div className="w-full h-1 mt-1 bg-blue-600 dark:bg-blue-300"></div>}
                    </div>
                  </div>



                  {
                    selectedTab === 'training' && (
                      <h1 className="mb-4 text-xl font-semibold text-gray-900 font-dm dark:text-white">
                        Resources<hr></hr>
                      </h1>)
                  }
                  {
                    selectedTab === 'policy' && (
                      <h1 className="mb-4 text-xl font-semibold text-gray-900 font-dm dark:text-white">
                        Categories<hr></hr>
                      </h1>)
                  }
                  {
                    selectedTab === 'actions' && (
                      <h1 className="mb-4 text-xl font-semibold text-gray-900 font-dm dark:text-white">
                        Actions<hr></hr>
                      </h1>)
                  }

                  <div className="mt-1 overflow-y-auto">
                    {selectedTab === 'training' && botId && <ChatsList chatbotId={botId} chatBotName={botName} openDefault={xyz?.state?.openResources} />}
                    {selectedTab === 'policy' && botId && <Categories chatbotId={botId} chatBotName={botName} />}
                    {selectedTab === 'actions' && botId && <Actions chatbotId={botId} chatBotName={botName} actions={actions} />}
                  </div>
                </Card>
              </div>

              <div className="w-full lg:w-[64.5%]">
                {selectedTab === 'training' && botId && (
                  <SingleChat
                    ws={ws}
                    chat={true}
                    userId_={currentUser?.user?.id}
                    chatId_={botId}
                    open={open}
                    onClose={() => setOpen(false)}
                    roomId={roomId}
                  />
                )}

                {selectedTab === 'policy' && <Rulesets chatbotId={botId} chatBotName={botName} actions={actions} />}
                {selectedTab === 'actions' && <RulesetsAction chatbotId={botId} chatBotName={botName} actions={actions} />}
              </div>
            </div>
          </ActionProvider>
        </CategoryProvider>

      )}
    </>
  );
};

export default LiveChat;