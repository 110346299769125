import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
  renderThumbMessages,
  renderTrackMessages,
  renderViewMessages,
} from '../scrollbar/Scrollbar';
import SingleMessage from './SingleMessage';
function TypingIndicator() {
  return (
    <div className="flex items-center">
      <div className="dot-bounce">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <span className="ml-2">Chatbot is typing...</span>
    </div>
  );
}
function Messages({ messages, isTyping }) {
  const scrollbarsRef = useRef(null);

  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-[calc(100%-74px)] w-full">
      <h1 className="mb-0 text-xl font-semibold text-gray-900 font-dm dark:text-white">
        Chat with Agent
      </h1>
      <Scrollbars
        renderTrackVertical={renderTrackMessages}
        renderThumbVertical={renderThumbMessages}
        renderView={renderViewMessages}
        ref={scrollbarsRef}
      >
        {messages.map((m, index) => (
          <SingleMessage key={index} message={m} />
        ))}
        {isTyping && <TypingIndicator />} {/* Render the typing indicator when isTyping is true */}
      </Scrollbars>
    </div>
  );
};

export default Messages;
