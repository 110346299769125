import { Scrollbars } from 'react-custom-scrollbars-2';
import { HiX } from 'react-icons/hi';
import { MdPayment } from 'react-icons/md';
import { TbLayout2, TbMessageDots, TbSitemap, TbHelp } from 'react-icons/tb';
import { MdModelTraining } from 'react-icons/md';
import { renderThumb, renderTrack, renderView } from '../scrollbar/Scrollbar';
import { AiOutlineAppstore } from 'react-icons/ai';

import SidebarCard from './SidebarCard';
import Card from '../card';
import { NavLink } from 'react-router-dom';
const SidebarHorizon = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 xl:block
        ${open ? '' : '-translate-x-full'}`}
    >
      <Card
        extra={`w-[260px]  sm:mr-4 h-[100vh] `}
        rounded={false}
        bgColor={"bgColorBlue"}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>

              <div className=" text-center">
                <NavLink to="/admin/dashboard">
                  <img src={process.env.REACT_APP_LOGO} alt="Azule Logo" style={{height:"120px", display:"unset"}} />
                </NavLink>
              </div>
              <div className="h-px bg-gray-200 dark:bg-gray-600" />

              {/* Nav item */}
              <div className="mt-10 px-12">
                <NavLink
                  to="/admin/dashboard"
                  className={({ isActive }) =>
                    `flex text-whiteColor dark:text-whiteColor hover:text-tertiary  no-underline mb-3 items-center ${isActive ? 'opacity-100' : 'opacity-60'
                    }`
                  }
                >
                  <TbLayout2 size={24} />
                  <span className="text-xl ml-2">Dashboard</span>
                </NavLink>

                <NavLink
                  to="/admin/widgets"
                  className={({ isActive }) =>
                    `flex text-whiteColor dark:text-whiteColor hover:text-tertiary no-underline mb-3 items-center ${isActive ? 'opacity-100' : 'opacity-60'
                    }`
                  }
                >
                  <TbSitemap size={24} />
                  <span className="text-xl ml-2">Agents</span>
                </NavLink>

                <NavLink
                  to="/admin/chat"
                  className={({ isActive }) =>
                    `flex text-whiteColor dark:text-whiteColor hover:text-tertiary no-underline mb-3 items-center ${isActive ? 'opacity-100' : 'opacity-60'
                    }`
                  }
                >
                  <TbMessageDots size={24} />
                  <span className="text-xl ml-2">Live Chat</span>
                </NavLink>

                <NavLink
                  to="/admin/train"
                  className={({ isActive }) =>
                    `flex text-whiteColor dark:text-whiteColor hover:text-tertiary no-underline mb-3 items-center ${isActive ? 'opacity-100' : 'opacity-60'
                    }`
                  }
                >
                  <MdModelTraining size={24} />
                  <span className="text-xl ml-2">Agent Training</span>
                </NavLink>
                <NavLink
                  to="/admin/integrations"
                  className={({ isActive }) =>
                    `flex text-whiteColor dark:text-whiteColor hover:text-tertiary no-underline mb-3 items-center ${isActive ? 'opacity-100' : 'opacity-60'
                    }`
                  }
                >
                  <MdModelTraining size={24} />
                  <span className="text-xl ml-2">Integrations</span>
                </NavLink>
              </div>
            </div>

            <div className="mt-[28px] mb-[50px] flex justify-center">
              <SidebarCard />
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
};

export default SidebarHorizon;
