import { useEffect, useState } from 'react';

import SidebarHorizon from '../components/admin/sidebar';
import Navbar from '../components/admin/navbar';
import { Outlet, useLocation } from 'react-router-dom';
import { FiAlignJustify } from 'react-icons/fi';
import { useAuthCheck } from '../useAuthCheck';
import { useSpring, animated } from 'react-spring';

const Admin = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();
  const isChatPage = location.pathname.includes('chat');
  const authUser = useAuthCheck();
  const [open, setOpen] = useState(true);
  console.log("here")

  useEffect(() => {
    const loadingDta = ()=>{
      setCurrentUser(authUser);
      
    }
    
    loadingDta()
  }, [authUser]);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  return (
   
    <>
      {
        currentUser &&
        <animated.div style={fadeIn} className="flex h-full w-full bg-gray-50 dark:bg-primaryColor">
          {/* <Sidebar open={open} onClose={() => setOpen(false)} /> */}
          <div className={`relative ${open ? 'md:w-[330px]' : 'w-0'}`}>
            <SidebarHorizon open={open} onClose={() => setOpen(false)} />
          </div>

          {/* Navbar & Main Content */}
          <div
            className={`relative ${open ? 'md:w-[calc(100vw-280px)] pr-5 w-full' : 'w-full px-3'
              }`}
          >
            {isChatPage ? (
              <div
                className={`bg-whiteColor dark:bg-card p-3 mt-3 rounded-lg ${open && 'hidden'
                  }`}
              >
                <span
                  className="flex text-xl text-gray-600 cursor-pointer dark:text-white xl:hidden "
                  onClick={() => setOpen(!open)}
                >
                  <FiAlignJustify className="w-5 h-5" />
                </span>
              </div>
            ) : (
              <Navbar onOpenSidenav={() => setOpen(!open)} />
            )}

            <div className={`${isChatPage ? 'mt-3' : 'mt-40 md:mt-32 pb-2.5'}`}>
              <Outlet />
              {/* <DashboardContent /> */}
            </div>
          </div>
        </animated.div>

      }
    </>

  );
};
export default Admin;
