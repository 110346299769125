import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import Transfer from '../dataDisplay/Transfer';
import Card from '../card';

import avatar1 from '../assets/avatar4.png';
import avatar2 from '../assets/avatar4.png';
import avatar3 from '../assets/avatar4.png';
import avatar4 from '../assets/avatar4.png';
import { NavLink } from 'react-router-dom';

function YourTransfers() {
  return (
    <Card extra={'!p-3 h-full'} >
      <div className="mb-4 flex flex-col p-2 text-center">
        <h4 className="ml-px text-lg font-semibold text-gray-900 dark:text-white">
          Recent {process.env.REACT_APP_NAME} Updates
        </h4>
      </div>
      <Transfer
        name="From Alex Manda"
        date="Today, 16:36"
        sum="0"
        avatar={avatar1}
      />
      <Transfer
        name="To Laura Santos"
        date="Today, 08:49"
        sum="0"
        avatar={avatar2}
      />
      <Transfer
        name="From Jadon S."
        date="Yesterday, 14:36"
        sum="0"
        avatar={avatar3}
      />
      <Transfer
        name="From Esthera J."
        date="Yesterday, 09:42"
        sum="0"
        avatar={avatar4}
      />

      <div className="mb-auto" />

      <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer p-4">
        <NavLink
          to="/admin/about"
          className="text-sm font-semibold text-gray-900 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white"
        >
          View all
        </NavLink>
        <div className="text-xl font-semibold text-gray-900 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
          <BsArrowRight />
        </div>
      </div>
    </Card>
  );
}

export default YourTransfers;
