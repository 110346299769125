import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

function ChatsList(props) {
  const { onStateChange, userId, onOpen } = props;
  const [chats, setChats] = useState([]);
  const {user} = useAuth()
  const { id } = props;
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(id.id)
    if (id) {
      setIsLoading(true)
      fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/config`, {
        method: 'POST',
        headers:{
          'Authorization':`Bearer ${user.token}`
        },
        body: JSON.stringify({
          operation: 'getAllChatroomIds',
          userId,
          agentId: id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setChats(res);
          setIsLoading(false)
        });
    }

  }, [props.id]);
    // New useEffect hook to select the first room when chats are updated
    useEffect(() => {
      if (chats.length > 0) {
        handleSelect(chats[0].chatroomId, 0);
      }
    }, [chats]);
  const handleSelect = (chatName, index) => {
    chats[index].isRead=true;
    setChats(chats)
    onStateChange(chatName, index + 1);
    onOpen();
    setSelectedRoom(index); // Set the selected room index
  };

  const Spinner = () => (
    <div className="w-16 h-16 border-t-2 border-blue-500 rounded-full animate-spin mx-auto mt-10"></div>
  );
  return (
    <>
      {chats.map((chat, index) => (
        <div key={index}>
          <div
            key={index}
            onClick={() => handleSelect(chat.chatroomId, index)}
            className={`flex w-full items-center gap-3 px-2 ${index === chats.length - 1 ? 'pt-3 pb-3' : 'border-b py-3'
              } cursor-pointer border-gray-200 dark:border-gray-600 lg:cursor-pointer ${index === selectedRoom
                ? 'bg-blue-100 dark:bg-blue-900' // Highlight selected room
                : 'hover:bg-gray-100 dark:hover:bg-gray-700' // Hover effect
              }`}
          >
            <div className="relative h-12 rounded-full">
              <img
                className="w-full h-full rounded-full"
                src={
                  'https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png'
                }
                alt=""
              />

              <div
                className={`absolute bottom-1 right-1 w-3 h-3 border-[1px] border-white rounded-full ${chat ? 'bg-green-600' : 'bg-red-800'
                  }`}
              />
            </div>

            <div className="" style={{"width":"80%"}}>
              <div className="flex items-center justify-between w-full">
                <h1 className={`text-lg font-normal capitalize text-gray-900 dark:text-white mb-0 ${!chat.isRead ? "bold-text":""}`}>
                  {chat?.user?.display_name || chat?.user?.name || chat?.user?.firstname || chat?.user?.email   || "Live Chat"}
                </h1>
              </div>
              <p className={`text-base font-normal tracking-normal text-gray-600 dark:text-gray-200 ellipsis ${!chat.isRead ? "bold-text":""}`}>
                {chat.lastMessage}
              </p>
              <p className="text-base font-normal tracking-normal text-gray-600 dark:text-gray-200">
                {process.env.REACT_APP_NAME}
              </p>
            </div>
          </div>
        </div>
      ))}
      {isLoading ?
        <Spinner /> :

        chats.length === 0 && (
          <div className="text-gray-400 text-lg pt-5 text-center">
            No chat rooms found
          </div>
        )}

    </>
  );
}

export default ChatsList;
