import { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(async () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        var response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}/verifyUserToken`, {
          'method': 'POST',
          'body': JSON.stringify({ "token": JSON.parse(storedUser)?.token })
        })
        if (response.status == 200) {
          setUser(JSON.parse(storedUser));
        }
      } catch (error) {

      }
      setLoading(false)
    }
    setLoading(false)
    
  }, []);

  const updateUser = (user) => {
    setUser(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      if(user?.user?.isFirstLogin){
        localStorage.setItem("showNodes", true);
        localStorage.setItem("showAgent", true);
        localStorage.setItem("showModal", true);
      }else{
        localStorage.removeItem("showNodes");
        localStorage.removeItem("showAgent");
        localStorage.removeItem("showModal");
      }
    } else {
      localStorage.removeItem("user");
    }
  };

  const logout = () => {
    // Add any additional logout logic here if needed
    setUser(null);
    localStorage.removeItem("user");
  };

  const value = {
    user,
    loading,
    updateUser,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
