import PropTypes from 'prop-types'
import { useContext, useState } from 'react'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { IconButton, Box, Typography, Divider, Button } from '@mui/material'
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material'
// project imports
import MainCard from '../../ui-component/cards/MainCard'
import NodeInputHandler from './NodeInputHandler'
import NodeOutputHandler from './NodeOutputHandler'
import AdditionalParamsDialog from '../../ui-component/dialog/AdditionalParamsDialog'
import { showToast } from '../../components/elements/ToastManager';
import { useAuth } from '../../context/AuthContext'; 
// const
import { baseURL } from '../../store/constant'
import { IconTrash, IconCopy } from '@tabler/icons'
import { flowContext } from '../../store/context/ReactFlowContext'
import InfoIcon from '@mui/icons-material/Info';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    background: theme.palette.card.main,
    color: theme.darkTextPrimary,
    border: 'solid 1px',
    borderColor: theme.palette.primary[200] + 75,
    width: '300px',
    height: 'auto',
    padding: '10px',
    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
    '&:hover': {
        borderColor: theme.palette.primary.main
    }
}))

// ===========================|| CANVAS NODE ||=========================== //

const CanvasNode = ({ data }) => {
    const theme = useTheme()
    const URLpath = document.location.pathname.toString().split('/')
    const chatflowId = URLpath[URLpath.length - 1] === 'canvas' ? '' : URLpath[URLpath.length - 1]
    const { deleteNode, duplicateNode, reactFlowInstance,errors } = useContext(flowContext)
    const [showDialog, setShowDialog] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modal, setModal] = useState({})
    const [dialogProps, setDialogProps] = useState({})
    const { user } = useAuth();
    const onDialogClicked = () => {
        const dialogProps = {
            data,
            inputParams: data.inputParams.filter((param) => param.additionalParams),
            confirmButtonName: 'Save',
            cancelButtonName: 'Cancel'
        }
        setDialogProps(dialogProps)
        setShowDialog(true)
    }
    const handleButtonDynamicClick = async (button, id) => {
        try {
            var response = await fetch(`${process.env.REACT_APP_MANAGEMENT_SERVER_URL}${button.url}?id=${id}`,{
                method: "GET",
                "headers": {
                  "Authorization": `Bearer ${user.token}`
                },
              })
            var res = await response.json()
            if ((response.status == 200 || response.status == 400 || response.status == 404) && res.content) {
                setShowModal(true)
                setModal({ 'title': res.title ? res.title : button.label, 'content': res.content })
            }
            else {
                showToast('error', 'Error fetching. Please try again later in some time');
            }
        }
        catch(error){
            showToast('error', 'Error fetching. Please try again later in some time');
            
        }

    }
    return (
        <>
            <CardWrapper
                content={false}
                sx={{
                    padding: 0,
                    borderColor: data.selected ? theme.palette.primary.main : theme.palette.text.secondary
                }}
                border={false}
            >
                <Box>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box style={{ width: 50, marginRight: 10, padding: 5 }}>
                            <div
                                style={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    cursor: 'grab'
                                }}
                            >
                                <img
                                    style={{ width: '100%', height: '100%', padding: 5, objectFit: 'contain' }}
                                    src={`${data.icon ? data.icon : "/logo.devagent.png"}`}
                                    alt='Notification'
                                />
                            </div>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 500
                                }}
                            >
                                {data.label}
                            </Typography>
                        </Box>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton
                            title='Delete'
                            onClick={() => {
                                deleteNode(data.id)
                            }}
                            sx={{ height: 35, width: 35, mr: 1, '&:hover': { color: 'red' } }}
                            color={theme?.customization?.isDarkMode ? theme.colors?.paper : 'inherit'}
                        >
                            <IconTrash />
                        </IconButton>
                    </div>
                    {(data.inputAnchors.length > 0 || data.inputParams.length > 0) && (
                        <>
                            <Divider />
                            <Box sx={{ background: theme.palette.asyncSelect.main, p: 1 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        textAlign: 'center'
                                    }}
                                >
                                    Variables
                                </Typography>
                            </Box>
                            <Divider />
                        </>
                    )}
                    {data.inputAnchors.map((inputAnchor, index) => (
                        <NodeInputHandler key={index} inputAnchor={inputAnchor} data={data} />
                    ))}
                    {data.inputParams.map((inputParam, index) => (
                        <NodeInputHandler key={index} inputParam={inputParam} data={data} errors={errors[data.name]||{}} disabled={data.id && inputParam?.isEditable == false && data.inputs[inputParam.name] ? true : false} />
                    ))}
                    {data.inputParams.find((param) => param.additionalParams) && (
                        <div style={{ textAlign: 'center' }}>
                            <Button sx={{ borderRadius: 25, width: '90%', mb: 2 }} variant='outlined' onClick={onDialogClicked}>
                                Add instructions
                            </Button>
                        </div>
                    )}
                    <Divider />
                    {/* <Box sx={{ background: theme.palette.asyncSelect.main, p: 1 }}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                textAlign: 'center'
                            }}
                        >
                            Actions
                        </Typography>
                    </Box>
                    {data?.buttons?.map((button, index) => (
                        <div style={{ textAlign: 'center' }}>
                            <Button sx={{ borderRadius: 25, width: '90%', mb: 2 }} variant='outlined' disabled={button.showOnEditOnly ? chatflowId ? false : true : false}
                                onClick={() => handleButtonDynamicClick(button,chatflowId)}
                            >{button.label}</Button>
                        </div>
                    ))} */}

                    <Divider />
                    {data.outputAnchors?.length>0?<Box sx={{ background: theme.palette.asyncSelect.main, p: 1 }}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                textAlign: 'center'
                            }}
                        >
                            Integrations
                        </Typography>
                    </Box>:""}
                    <Divider />

                    {data.outputAnchors.map((outputAnchor, index) => (
                        <NodeOutputHandler key={index} outputAnchor={outputAnchor} data={data} />
                    ))}
                    {data.setuphelp ? <div style={{ "display": "flex" }}><Button color='info' sx={{ background: theme.palette.asyncSelect.main, p: 1 }} style={{ margin: "auto" }}
                        startIcon={<InfoIcon></InfoIcon>}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                textAlign: 'center'
                            }}
                        >
                            How to setup
                        </Typography>
                    </Button>
                        <Divider /></div> : ""
                    }

                </Box>
            </CardWrapper>
            <AdditionalParamsDialog
                show={showDialog}
                dialogProps={dialogProps}
                onCancel={() => setShowDialog(false)}
            ></AdditionalParamsDialog>

            <Dialog
                onClose={() => setShowModal(false)}
                open={showModal}
                fullWidth
                maxWidth='sm'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle>{modal.title} </DialogTitle>
                <DialogContent>
                    <div
                        dangerouslySetInnerHTML={{ __html: modal.content }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

CanvasNode.propTypes = {
    data: PropTypes.object
}

export default CanvasNode
